import moment from 'moment';
import lodash from 'lodash';
import { requestWrapper } from '@vjs/helpers';
import { Helper } from '@common/src/helpers';
import { eventBus } from '@common/mixins';
import GlobalVue from '@common/src/vue';
import 'moment/dist/locale/ru';
import 'moment/dist/locale/kk';

export default function () {
  window._ = lodash;
  moment.locale(window.core_project.locale === 'kz' ? 'kk' : 'ru');
  window.moment = moment;

  window.handleYm = (goal) => {
    try {
      // eslint-disable-next-line
      const ymId = window.Ya._metrika.getCounters()[0].id;
      window.ym(ymId, 'reachGoal', goal);
    } catch (err) {
      console.error(err);
    }
  };

  const currentDate = moment().startOf('day');
  window.currentDayHelper = {
    day: currentDate.get('date'),
    month: currentDate.get('month') + 1,
    year: currentDate.get('year'),
    date: currentDate.format('DD.MM.YYYY'),
    DDF: currentDate.format('YYYY/MM/DD'), // Date Default Format
    timestamp: new Date(currentDate).getTime(),
  };

  window.show_notice = (messages = [], type = 'success') => {
    let result = '';
    if (_.isString(messages) || _.isNumber(messages)) {
      result = messages;
    } else if (_.isArray(messages)) {
      _.forEach(messages, (value) => {
        if (_.isArray(value)) {
          _.forEach(value, (message) => {
            result += `${message}<br/>`;
          });
        } else {
          result += `${value}<br/>`;
        }
      });
    }
    if (result !== '') {
      let tmpType = type;
      if (['notice', 'message'].includes(type)) {
        tmpType = 'success';
      }
      if (['warning'].includes(type)) {
        tmpType = 'warn';
      }
      GlobalVue.$notify({
        type: tmpType,
        text: result,
      });
    }
  };

  window.requestWrapper = requestWrapper;
  window.eventBus = eventBus;

  window.requestGetDataHelper = async (vm, {
    url, variableName, returnData, params, hardSet, fullRes, withLoader = true,
  }) => {
    if (withLoader) {
      vm.$globalLoading.show();
    }
    const res = await requestWrapper.call(vm, { url, params });

    if (withLoader && !res.data?.redirect) {
      vm.$globalLoading.hide();
    }

    if (!res.error) {
      const data = (fullRes ? res : res.data) ?? {};
      if (returnData) {
        return data;
      }

      if (variableName) {
        if (hardSet) {
          _.set(vm, variableName, data);
        } else {
          _.set(vm, variableName, { ...vm[variableName], ...data });
        }
      }
    } else {
      Helper.handlerResponseErrorNew(vm, res);
      return false;
    }
  };
}
