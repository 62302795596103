import { createApp, defineAsyncComponent } from 'vue';

const VRelatedPieCharts = defineAsyncComponent(() => import('../VueJS/views/VRelatedPieCharts'));
const VBarChart = defineAsyncComponent(() => import('../VueJS/views/VBarChart'));
const VPopulationPyramid = defineAsyncComponent(() => import('../VueJS/views/VPopulationPyramid'));
const VRelatedBarPieChart = defineAsyncComponent(() => import('../VueJS/views/VRelatedBarPieChart'));
const VTableChart = defineAsyncComponent(() => import('../VueJS/views/VTableChart'));
const VStatisticBlock = defineAsyncComponent(() => import('../VueJS/views/VStatisticBlock'));
const VPieChart = defineAsyncComponent(() => import('../VueJS/views/VPieChart'));

export default function (mixBoot) {
  if (document.getElementById('v-statistic-app')) {
    const VueApp = createApp({});
    mixBoot(VueApp, false);
    VueApp.component('v-related-pie-charts', VRelatedPieCharts);
    VueApp.component('v-bar-chart', VBarChart);
    VueApp.component('v-population-pyramid', VPopulationPyramid);
    VueApp.component('v-related-bar-pie-chart', VRelatedBarPieChart);
    VueApp.component('v-table-chart', VTableChart);
    VueApp.component('v-statistic-block', VStatisticBlock);
    VueApp.component('v-pie-chart', VPieChart);
    VueApp.mount('#v-statistic-app');
    window.vStatisticApp = VueApp;
  }
}
