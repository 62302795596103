import { createApp, defineAsyncComponent } from 'vue';

const VDiplomaContingentForm = defineAsyncComponent(() => import('../VueJS/views/VDiplomaContingentForm/index'));

export default function (mixBoot) {
  if (document.getElementById('v-diploma-contingent-form-app')) {
    const VueApp = createApp({});
    mixBoot(VueApp, false);
    VueApp.component('VDiplomaContingentForm', VDiplomaContingentForm);
    VueApp.mount('#v-diploma-contingent-form-app');
    window.vDiplomaContingentFormApp = VueApp;
  }
}
