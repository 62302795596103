import { createApp, defineAsyncComponent } from 'vue';

const VStatementCreateView = defineAsyncComponent(() => import('../VueJS/views/VStatementCreateView/index'));

export default function (mixBoot) {
  if (document.getElementById('v-statement-create-view-app')) {
    const VueApp = createApp({});
    mixBoot(VueApp, false);
    VueApp.component('v-statement-create-view', VStatementCreateView);
    VueApp.mount('#v-statement-create-view-app');
    window.vStatementCreateViewApp = VueApp;
  }
}
