import vAdaptiveMenuApp from './v-adaptive-menu-app';
import vAdmissionResultListApp from './v-admission-result-list-app';
import vAdmissionResultOrgListApp from './v-admission-result-org-list-app';
import vApplicationListViewApp from './v-application-list-view-app';
import vCatalogListViewApp from './v-catalog-list-view-app';
import vContingentMonitoringApp from './v-contingent-monitoring-app';
import vDiplomaContingentFormApp from './v-diploma-contingent-form-app';
import vDiplomaFormApp from './v-diploma-form-app';
import vDiplomaListApp from './v-diploma-list-app';
import vDocFlowFormApp from './v-doc-flow-form-app';
import vExamQuestionsApp from './v-exam-questions-app';
import vExamThemesApp from './v-exam-themes-app';
import vExamTicketsApp from './v-exam-tickets-app';
import vInstaApp from './v-insta-app';
import vJournalFormApp from './v-journal-form-app';
import vLibraryApp from './v-library-app';
import vLoginFormApp from './v-login-form-app';
import vMenuApp from './v-menu-app';
import vMonitoringListApp from './v-monitoring-list-app';
import vPassportPageViewApp from './v-passport-page-view-app';
import vResetPassApp from './v-reset-pass-app';
import vScheduleViewApp from './v-schedule-view-app';
import vStatementAdmissionFormApp from './v-statement-admission-form-app';
import vStatementCreateView from './v-statement-create-view';
import vStatementFilesEditApp from './v-statement-files-edit-app';
import vStatisticApp from './v-statistic-app';
import vTicketProcessApp from './v-ticket-process-app';

export default function (mixBoot) {
  vAdaptiveMenuApp(mixBoot);
  vAdmissionResultListApp(mixBoot);
  vAdmissionResultOrgListApp(mixBoot);
  vApplicationListViewApp(mixBoot);
  vCatalogListViewApp(mixBoot);
  vContingentMonitoringApp(mixBoot);
  vDiplomaContingentFormApp(mixBoot);
  vDiplomaFormApp(mixBoot);
  vDiplomaListApp(mixBoot);
  vDocFlowFormApp(mixBoot);
  vExamQuestionsApp(mixBoot);
  vExamThemesApp(mixBoot);
  vExamTicketsApp(mixBoot);
  vInstaApp(mixBoot);
  vJournalFormApp(mixBoot);
  vLibraryApp(mixBoot);
  vLoginFormApp(mixBoot);
  vMenuApp(mixBoot);
  vMonitoringListApp(mixBoot);
  vPassportPageViewApp(mixBoot);
  vResetPassApp(mixBoot);
  vScheduleViewApp(mixBoot);
  vStatementAdmissionFormApp(mixBoot);
  vStatementCreateView(mixBoot);
  vStatementFilesEditApp(mixBoot);
  vStatisticApp(mixBoot);
  vTicketProcessApp(mixBoot);
}
