import {
  h, ref, computed, getCurrentInstance, toRaw,
} from 'vue';

import QIcon from '../icon/QIcon.js';

import useDark, { useDarkProps } from '../../composables/private.use-dark/use-dark.js';
import useSize, { useSizeProps } from '../../composables/private.use-size/use-size.js';
import useRefocusTarget from '../../composables/private.use-refocus-target/use-refocus-target.js';
import { useFormProps, useFormInject } from '../../composables/use-form/private.use-form.js';

import { createComponent } from '../../utils/private.create/create.js';
import optionSizes from '../../utils/private.option-sizes/option-sizes.js';
import { stopAndPrevent } from '../../utils/event/event.js';
import { hSlot, hMergeSlot } from '../../utils/private.render/render.js';

const svg = h('div', {
  class: 'sn-radio__bg s-pos-absolute',
}, [
  h('div', { class: 'sn-radio__outer-circle s-pos-absolute-full' }),
  h('div', { class: 'sn-radio__inner-circle s-pos-absolute-full' }),
]);

export default createComponent({
  name: 'QRadio',

  props: {
    ...useDarkProps,
    ...useSizeProps,
    ...useFormProps,

    modelValue: { required: true },
    val: { required: true },

    label: String,
    leftLabel: Boolean,

    checkedIcon: String,
    uncheckedIcon: String,

    color: String,
    keepColor: Boolean,
    dense: Boolean,

    disable: Boolean,
    tabindex: [String, Number],
  },

  emits: ['update:modelValue'],

  setup(props, { slots, emit }) {
    const { proxy } = getCurrentInstance();

    const isDark = useDark(props, proxy.$q);
    const sizeStyle = useSize(props, optionSizes);

    const rootRef = ref(null);
    const { refocusTargetEl, refocusTarget } = useRefocusTarget(props, rootRef);

    const isTrue = computed(() => toRaw(props.modelValue) === toRaw(props.val));

    const classes = computed(() => `sn-radio sn--cursor-pointer sn--no-outline sn--row sn--inline sn--no-wrap
    ${props.disable === true ? ' sn--disabled' : ''}
    ${!props.disable ? ' sn--cursor-pointer' : ''
}${isDark.value === true ? ' sn-radio--dark' : ''
}${props.dense === true ? ' sn-radio--dense' : ''
}${props.leftLabel === true ? ' sn--reverse' : ''}`);

    const innerClass = computed(() => {
      const color = props.color !== void 0 && (
        props.keepColor === true
        || isTrue.value === true
      )
        ? ` s-c-${props.color}`
        : '';

      return 'sn-radio__inner sn--relative-position '
        + `sn-radio__inner--${isTrue.value === true ? 'active' : 'falsy'}${color}`;
    });

    const icon = computed(() => (isTrue.value === true
      ? props.checkedIcon
      : props.uncheckedIcon
    ) || null);

    const tabindex = computed(() => (
      props.disable === true ? -1 : props.tabindex || 0
    ));

    const formAttrs = computed(() => {
      const prop = { type: 'radio' };

      props.name !== void 0 && Object.assign(prop, {
        // see https://vuejs.org/guide/extras/render-function.html#creating-vnodes (.prop)
        '.checked': isTrue.value === true,
        '^checked': isTrue.value === true ? 'checked' : void 0,
        name: props.name,
        value: props.val,
      });

      return prop;
    });

    const injectFormInput = useFormInject(formAttrs);

    function onClick(e) {
      if (e !== void 0) {
        stopAndPrevent(e);
        refocusTarget(e);
      }

      if (props.disable !== true && isTrue.value !== true) {
        emit('update:modelValue', props.val, e);
      }
    }

    function onKeydown(e) {
      if (e.keyCode === 13 || e.keyCode === 32) {
        stopAndPrevent(e);
      }
    }

    function onKeyup(e) {
      if (e.keyCode === 13 || e.keyCode === 32) {
        onClick(e);
      }
    }

    // expose public methods
    Object.assign(proxy, { set: onClick });

    return () => {
      const content = icon.value !== null
        ? [
          h('div', {
            key: 'icon',
            class: 'sn-radio__icon-container s-pos-absolute-full sn--flex sn--flex-center sn--no-wrap',
          }, [
            h(QIcon, {
              class: 'sn-radio__icon',
              name: icon.value,
            }),
          ]),
        ]
        : [svg];

      props.disable !== true && injectFormInput(
        content,
        'unshift',
        ' sn-radio__native sn-ma-none sn-pa-none',
      );

      const child = [
        h('div', {
          class: innerClass.value,
          style: sizeStyle.value,
          'aria-hidden': 'true',
        }, content),
      ];

      if (refocusTargetEl.value !== null) {
        child.push(refocusTargetEl.value);
      }

      const label = props.label !== void 0
        ? hMergeSlot(slots.default, [props.label])
        : hSlot(slots.default);

      label !== void 0 && child.push(
        h('div', {
          class: 'sn-radio__label sn-anchor--skip',
        }, label),
      );

      return h('div', {
        ref: rootRef,
        class: classes.value,
        tabindex: tabindex.value,
        role: 'radio',
        'aria-label': props.label,
        'aria-checked': isTrue.value === true ? 'true' : 'false',
        'aria-disabled': props.disable === true ? 'true' : void 0,
        onClick,
        onKeydown,
        onKeyup,
      }, child);
    };
  },
});
