import _ from 'lodash';
import axios from 'axios';
import eventHub from '@vjs/config/eventHub';

export default {
  state: {
    urlExam: {},
    urlTicketGroup: {},
    questions: [],
    question: {},
    questionEditId: 0,
    questionAdd: 0,
    answerEditId: 0,
    group: {},
    imgErrorMsg: '',
  },
  mutations: {
    setImgErrorMsg(state, payload) {
      state.imgErrorMsg = payload;
    },
    setUrlExam(state, payload) {
      state.urlExam = payload;
    },
    setUrlThemeGroup(state, payload) {
      state.urlThemeGroup = payload;
    },
    setUrlTicketGroup(state, payload) {
      state.urlTicketGroup = payload;
    },
    setQuestions(state, payload) {
      state.questions = payload;
    },
    setQuestion(state, payload) {
      state.question = payload;
    },
    setQuestionDestroy(state) {
      const index = _.findIndex(state.questions, ['id', state.questionEditId]);
      if (index !== -1) {
        state.questions.splice(index, 1);
      }
    },
    setQuestionsById(state, payload) {
      const index = _.findIndex(state.questions, ['id', state.questionEditId]);
      if (index !== -1) {
        state.questions[index] = { ...payload };
      } else {
        state.questions[state.questions.length] = { ...payload };
      }
    },
    setQuestionsVisible(state, payload) {
      const index = _.findIndex(state.questions, ['id', payload.id]);
      if (index !== -1) {
        state.questions[index].is_visible = payload.is_visible;
      }
    },
    setQuestionEditId(state, payload = 0) {
      state.questionEditId = payload;
    },
    setQuestionAdd(state, payload = 0) {
      state.questionAdd = payload;
    },
    setAnswerDestroy(state) {
      const questionIndex = _.findIndex(state.questions, ['id', state.questionEditId]);
      const question = state.questions[questionIndex];
      const newAnswers = [];
      _.forEach(question.answers, (item) => {
        if (item.id !== state.answerEditId) {
          newAnswers.push(item);
        }
      });
      question.answers = newAnswers;
      state.questions[questionIndex] = question;
    },
    setAnswerEditId(state, payload = 0) {
      state.answerEditId = payload;
    },
    setGroup(state, payload) {
      state.group = payload;
    },
  },
  getters: {
    getImgErrorMsg(state) {
      return state.imgErrorMsg;
    },
    getUrlExam(state) {
      return state.urlExam;
    },
    getQuestions(state) {
      return state.questions;
    },
    getQuestion(state) {
      return state.question;
    },
    getQuestionsById(state) {
      return _.findIndex(state.questions, ['id', state.questionEditId]);
    },
    getQuestionEditId(state) {
      return state.questionEditId;
    },
    getQuestionAdd(state) {
      return state.questionAdd;
    },
    getAnswerEditId(state) {
      return state.answerEditId;
    },
    getGroup(state) {
      return state.group;
    },
  },
  actions: {
    GET_QUESTIONS: async ({ commit, state }) => {
      try {
        const url = `${state.urlExam}/list`;
        const { data } = await axios({
          method: 'get',
          url,
        });
        commit('setQuestions', data.questions);
      } catch (error) {
        console.error(error);
      }
    },
    GET_GROUP: async ({ commit, state }) => {
      try {
        const { data } = await axios({
          method: 'get',
          url: `${state.urlExam}/group`,
        });

        commit('setGroup', data.group);
      } catch (error) {
        console.error(error);
      }
    },
    CREATE_QUESTION: async ({ commit, state }, payload) => {
      try {
        payload.append('exam_questions_group_id', state.group.id);
        const { data } = await axios.post(`${state.urlExam}`, payload);
        commit('setQuestionsById', data.question);
        commit('setQuestionAdd');

        return {
          question: data.question,
          text: 'notice.created',
          type: 'success',
        };
      } catch (error) {
        return {
          data: error.response.data,
          text: 'notice.error',
          type: 'error',
        };
      }
    },
    EDIT_QUESTION: async ({ commit, state }, payload) => {
      try {
        payload.append('_method', 'PATCH');
        payload.append('exam_questions_group_id', state.group.id);
        const { data } = await axios.post(`${state.urlExam}/${state.questionEditId}`, payload);
        commit('setQuestionsById', data.question);
        commit('setQuestionEditId');

        return {
          text: 'notice.saved',
          type: 'success',
        };
      } catch (error) {
        return {
          data: error.response.data,
          text: 'notice.error',
          type: 'error',
        };
      }
    },
    DELETE_QUESTION: async ({ commit, state }) => {
      try {
        await axios.post(
          `${state.urlExam}/${state.questionEditId}`,
          {
            _method: 'DELETE',
          },
        );
        commit('setQuestionDestroy');
        commit('setQuestionEditId');
        window.location.reload();

        return {
          text: 'notice.deleted',
          type: 'success',
        };
      } catch (error) {
        return {
          data: error.response.data,
          text: 'notice.error',
          type: 'error',
        };
      }
    },
    SHOW_QUESTION: async ({ commit, state }, payload) => {
      try {
        const { data } = await axios({
          method: 'get',
          url: `${state.urlExam}/${payload}/show`,
        });
        if (data.redirect) {
          window.location.href = (data.redirect);
          return;
        }
        commit('setQuestionsVisible', data.question);
      } catch (error) {
        console.error(error);
      }
    },
    SHOW_ALL_QUESTIONS: async ({ commit, state }) => {
      try {
        const { data } = await axios({
          method: 'get',
          url: `${state.urlExam}/show-all`,
        });
        if (data.redirect) {
          window.location.href = (data.redirect);
          return;
        }
        data.questions.forEach((el) => commit('setQuestionsVisible', el));
      } catch (error) {
        console.error(error);
      }
    },
    HIDE_ALL_QUESTIONS: async ({ commit, state }) => {
      try {
        const { data } = await axios({
          method: 'get',
          url: `${state.urlExam}/hide-all`,
        });
        if (data.redirect) {
          window.location.href = (data.redirect);
          return;
        }

        data.questions.forEach((el) => commit('setQuestionsVisible', el));

        return {
          text: data.message,
          type: 'success',
        };
      } catch (error) {
        return {
          text: error.response.data.message,
          type: 'error',
        };
      }
    },
    HIDE_QUESTION: async ({ commit, state }, payload) => {
      try {
        const { data } = await axios({
          method: 'get',
          url: `${state.urlExam}/${payload}/hide`,

        });
        if (data.redirect) {
          window.location.href = (data.redirect);
          return;
        }
        commit('setQuestionsVisible', data.question);

        return {
          text: data.message,
          type: 'success',
        };
      } catch (error) {
        return {
          text: error.response.data.message,
          type: 'error',
        };
      }
    },
    FILE_QUESTION: async ({ state }, { payload, file, resolve }) => {
      try {
        eventHub.$emit('loadFile', true);
        payload.append('file', file);
        payload.append('responseType', 'json');
        const { data } = await axios.post(`${state.urlExam}/upload-content`, payload);
        resolve(data);
        eventHub.$emit('loadFile', false);
      } catch (error) {
        //  Костыль с сообщением, если файл не прошел валидацию по размеру
        this.$notify({
          text: state.imgErrorMsg,
          type: 'error',
        });
        eventHub.$emit('loadFile', false);
        console.error(error);
        return error;
      }
    },
    FILE_TICKET: async ({ state }, { payload, file, resolve }) => {
      try {
        eventHub.$emit('loadFile', true);
        payload.append('file', file);
        payload.append('responseType', 'json');
        const { data } = await axios.post(`${state.urlTicketGroup}/upload-content`, payload);
        resolve(data);
        eventHub.$emit('loadFile', false);
      } catch (error) {
        //    Костыль с сообщением, если файл не прошел валидацию по размеру
        this.$notify({
          text: state.imgErrorMsg,
          type: 'error',
        });
        eventHub.$emit('loadFile', false);
        console.error(error);
        return error;
      }
    },
    FILE_THEME: async ({ state }, { payload, file, resolve }) => {
      try {
        eventHub.$emit('loadFile', true);
        payload.append('file', file);
        payload.append('responseType', 'json');
        const { data } = await axios.post(`${state.urlThemeGroup}/upload-content`, payload);
        resolve(data);
        eventHub.$emit('loadFile', false);
      } catch (error) {
        //    Костыль с сообщением, если файл не прошел валидацию по размеру
        this.$notify({
          text: state.imgErrorMsg,
          type: 'error',
        });
        eventHub.$emit('loadFile', false);
        console.error(error);
        return error;
      }
    },
  },
};
