import { defineAsyncComponent } from 'vue';

const ImageViewer = defineAsyncComponent(() => import('@common/components/ImageViewer'));
const VoiceRecorder = defineAsyncComponent(() => import('@common/components/VoiceRecorder'));
const VInfoCard = defineAsyncComponent(() => import('@common/components/VInfoCard'));
const ImageModal = defineAsyncComponent(() => import('./ImageModal'));
const AudioPlayer = defineAsyncComponent(() => import('./AudioPlayer'));

export default function (Vue) {
  Vue.component('image-modal', ImageModal);
  Vue.component('vue-image-modal', ImageModal);
  Vue.component('vue-audio-player', AudioPlayer);
  Vue.component('image-viewer', ImageViewer);
  Vue.component('voice-recorder', VoiceRecorder);
  Vue.component('v-info-card', VInfoCard);
}
