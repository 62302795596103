import { defineAsyncComponent } from 'vue';

const ImageViewer = defineAsyncComponent(() => import('@common/components/ImageViewer'));
const VoiceRecorder = defineAsyncComponent(() => import('@common/components/VoiceRecorder'));
const VInfoCard = defineAsyncComponent(() => import('@common/components/VInfoCard'));
const ImageModal = defineAsyncComponent(() => import('./ImageModal'));
const AudioPlayer = defineAsyncComponent(() => import('./AudioPlayer'));

export default function (Vue) {
  Vue.component('ImageModal', ImageModal);
  Vue.component('VueImageModal', ImageModal);
  Vue.component('VueAudioPlayer', AudioPlayer);
  Vue.component('ImageViewer', ImageViewer);
  Vue.component('VoiceRecorder', VoiceRecorder);
  Vue.component('VInfoCard', VInfoCard);
}
