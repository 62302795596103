import { defineAsyncComponent } from 'vue';

const TrainingScheduleForm = defineAsyncComponent(() => import('./TrainingSchedule/Form'));
const ContingentForm = defineAsyncComponent(() => import('./Contingent/Form'));
const ContingentAdditionalInfoForm = defineAsyncComponent(() => import('./Contingent/AdditionalInfoForm'));
const ChildrenForm = defineAsyncComponent(() => import('./Children/Form'));
const SelectOrganizationForm = defineAsyncComponent(() => import('./SelectOrganization/Form'));
const SelectRoleForm = defineAsyncComponent(() => import('./SelectRole/Form'));
const HomeworkForm = defineAsyncComponent(() => import('./Homework/Form'));
const AssignedHomework = defineAsyncComponent(() => import('./AssignedHomework/Form'));
const AssignedHomeworkAnswer = defineAsyncComponent(() => import('./AssignedHomeworkAnswer/Form'));
const AssignedHomeworkAnswerVerify = defineAsyncComponent(() => import('./AssignedHomeworkAnswerVerify/Form'));
const AssignedHomeworkRevision = defineAsyncComponent(() => import('./AssignedHomeworkRevision/Form'));
const OnlineLectureForm = defineAsyncComponent(() => import('./OnlineLecture/Form'));
const ExamThemesForm = defineAsyncComponent(() => import('./ExamThemes/Form'));
const ExamTicketsForm = defineAsyncComponent(() => import('./ExamTickets/Form'));
const ExamQuestionGroupForm = defineAsyncComponent(() => import('./ExamQuestionGroup/Form'));
const HomeworkQuestionGroupForm = defineAsyncComponent(() => import('./HomeworkQuestionGroup/Form'));
const SubgroupsForm = defineAsyncComponent(() => import('./Subgroups/Form'));
const SubgroupsManagement = defineAsyncComponent(() => import('./Subgroups/Management'));
const GroupForm = defineAsyncComponent(() => import('./Group/Form'));
const EducationModuleForm = defineAsyncComponent(() => import('./EducationModule/Form'));
const CalendarThematicPlan = defineAsyncComponent(() => import('./CalendarThematicPlan/Form'));
const VCalendarThematicPlanShow = defineAsyncComponent(() => import('./CalendarThematicPlan/Show/VCalendarThematicPlanShow.vue'));
const VExternalStatementSignButtons = defineAsyncComponent(() => import('./VExternalStatementSign/Buttons/VStatementSignButtons'));
const VExternalStatementSignModals = defineAsyncComponent(() => import('./VExternalStatementSign/Modals/VStatementSignModals'));
const VStatementSerivceSignModals = defineAsyncComponent(() => import('./VStatementServiceSign/Modals/VStatementSignModals'));
const VStatementSerivceSignButtons = defineAsyncComponent(() => import('./VStatementServiceSign/Buttons/VStatementSignButtons'));
const VGovServiceSign = defineAsyncComponent(() => import('./UserGovServiceStatement/Modals/VGovServiceSign'));
const VEducationResultForm = defineAsyncComponent(() => import('./EducationResult/Form'));
const PersonnelAdditionalInfoForm = defineAsyncComponent(() => import('./Personnel/AdditionalInfoForm'));
const VideoConferencesCreateForm = defineAsyncComponent(() => import('./VideoConferencesCreateForm/Form'));

export default function (Vue) {
  Vue.component('vue-training-schedule-form', TrainingScheduleForm);
  Vue.component('vue-contingent-form', ContingentForm);
  Vue.component('vue-contingent-additional-info-form', ContingentAdditionalInfoForm);
  Vue.component('vue-children-form', ChildrenForm);
  Vue.component('vue-select-organization-form', SelectOrganizationForm);
  Vue.component('vue-select-role-form', SelectRoleForm);

  Vue.component('vue-homework-form', HomeworkForm);
  Vue.component('vue-assigned-homework-form', AssignedHomework);
  Vue.component('vue-assigned-homework-answer-form', AssignedHomeworkAnswer);
  Vue.component('vue-assigned-homework-answer-verify-form', AssignedHomeworkAnswerVerify);
  Vue.component('vue-assigned-homework-revision-form', AssignedHomeworkRevision);

  Vue.component('vue-online-lecture-form', OnlineLectureForm);

  Vue.component('vue-exam-themes-form', ExamThemesForm);
  Vue.component('vue-exam-tickets-form', ExamTicketsForm);
  Vue.component('vue-exam-question-group-form', ExamQuestionGroupForm);
  Vue.component('vue-homework-question-group-form', HomeworkQuestionGroupForm);

  Vue.component('vue-subgroups-form', SubgroupsForm);
  Vue.component('vue-subgroups-management', SubgroupsManagement);
  Vue.component('vue-group-form', GroupForm);

  Vue.component('vue-education-module-form', EducationModuleForm);
  Vue.component('vue-calendar-thematic-plan-form', CalendarThematicPlan);
  Vue.component('v-calendar-thematic-plan-show', VCalendarThematicPlanShow);

  Vue.component('v-external-statement-sign-buttons', VExternalStatementSignButtons);
  Vue.component('v-external-statement-sign-modals', VExternalStatementSignModals);

  Vue.component('v-statement-service-sign-buttons', VStatementSerivceSignButtons);
  Vue.component('v-statement-service-sign-modals', VStatementSerivceSignModals);

  Vue.component('v-gov-service-sign-modals', VGovServiceSign);
  Vue.component('v-education-result-form', VEducationResultForm);
  Vue.component('v-personnel-additional-info-form', PersonnelAdditionalInfoForm);

  Vue.component('v-video-conferences-create-form', VideoConferencesCreateForm);
}
