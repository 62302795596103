import { defineAsyncComponent } from 'vue';

const TrainingScheduleForm = defineAsyncComponent(() => import('./TrainingSchedule/Form'));
const ContingentForm = defineAsyncComponent(() => import('./Contingent/Form'));
const ContingentAdditionalInfoForm = defineAsyncComponent(() => import('./Contingent/AdditionalInfoForm'));
const ChildrenForm = defineAsyncComponent(() => import('./Children/Form'));
const SelectOrganizationForm = defineAsyncComponent(() => import('./SelectOrganization/Form'));
const SelectRoleForm = defineAsyncComponent(() => import('./SelectRole/Form'));
const HomeworkForm = defineAsyncComponent(() => import('./Homework/Form'));
const AssignedHomework = defineAsyncComponent(() => import('./AssignedHomework/Form'));
const AssignedHomeworkAnswer = defineAsyncComponent(() => import('./AssignedHomeworkAnswer/Form'));
const AssignedHomeworkAnswerVerify = defineAsyncComponent(() => import('./AssignedHomeworkAnswerVerify/Form'));
const AssignedHomeworkRevision = defineAsyncComponent(() => import('./AssignedHomeworkRevision/Form'));
const OnlineLectureForm = defineAsyncComponent(() => import('./OnlineLecture/Form'));
const ExamThemesForm = defineAsyncComponent(() => import('./ExamThemes/Form'));
const ExamTicketsForm = defineAsyncComponent(() => import('./ExamTickets/Form'));
const ExamQuestionGroupForm = defineAsyncComponent(() => import('./ExamQuestionGroup/Form'));
const HomeworkQuestionGroupForm = defineAsyncComponent(() => import('./HomeworkQuestionGroup/Form'));
const SubgroupsForm = defineAsyncComponent(() => import('./Subgroups/Form'));
const SubgroupsManagement = defineAsyncComponent(() => import('./Subgroups/Management'));
const GroupForm = defineAsyncComponent(() => import('./Group/Form'));
const EducationModuleForm = defineAsyncComponent(() => import('./EducationModule/Form'));
const CalendarThematicPlan = defineAsyncComponent(() => import('./CalendarThematicPlan/Form'));
const VCalendarThematicPlanShow = defineAsyncComponent(() => import('./CalendarThematicPlan/Show/VCalendarThematicPlanShow.vue'));
const VExternalStatementSignButtons = defineAsyncComponent(() => import('./VExternalStatementSign/Buttons/VStatementSignButtons'));
const VExternalStatementSignModals = defineAsyncComponent(() => import('./VExternalStatementSign/Modals/VStatementSignModals'));
const VStatementSerivceSignModals = defineAsyncComponent(() => import('./VStatementServiceSign/Modals/VStatementSignModals'));
const VStatementSerivceSignButtons = defineAsyncComponent(() => import('./VStatementServiceSign/Buttons/VStatementSignButtons'));
const VGovServiceSign = defineAsyncComponent(() => import('./UserGovServiceStatement/Modals/VGovServiceSign'));
const VEducationResultForm = defineAsyncComponent(() => import('./EducationResult/Form'));
const PersonnelAdditionalInfoForm = defineAsyncComponent(() => import('./Personnel/AdditionalInfoForm'));
const VideoConferencesCreateForm = defineAsyncComponent(() => import('./VideoConferencesCreateForm/Form'));

export default function (Vue) {
  Vue.component('VueTrainingScheduleForm', TrainingScheduleForm);
  Vue.component('VueContingentForm', ContingentForm);
  Vue.component('VueContingentAdditionalInfoForm', ContingentAdditionalInfoForm);
  Vue.component('VueChildrenForm', ChildrenForm);
  Vue.component('VueSelectOrganizationForm', SelectOrganizationForm);
  Vue.component('VueSelectRoleForm', SelectRoleForm);

  Vue.component('VueHomeworkForm', HomeworkForm);
  Vue.component('VueAssignedHomeworkForm', AssignedHomework);
  Vue.component('VueAssignedHomeworkAnswerForm', AssignedHomeworkAnswer);
  Vue.component('VueAssignedHomeworkAnswerVerifyForm', AssignedHomeworkAnswerVerify);
  Vue.component('VueAssignedHomeworkRevisionForm', AssignedHomeworkRevision);

  Vue.component('VueOnlineLectureForm', OnlineLectureForm);

  Vue.component('VueExamThemesForm', ExamThemesForm);
  Vue.component('VueExamTicketsForm', ExamTicketsForm);
  Vue.component('VueExamQuestionGroupForm', ExamQuestionGroupForm);
  Vue.component('VueHomeworkQuestionGroupForm', HomeworkQuestionGroupForm);

  Vue.component('VueSubgroupsForm', SubgroupsForm);
  Vue.component('VueSubgroupsManagement', SubgroupsManagement);
  Vue.component('VueGroupForm', GroupForm);

  Vue.component('VueEducationModuleForm', EducationModuleForm);
  Vue.component('VueCalendarThematicPlanForm', CalendarThematicPlan);
  Vue.component('VCalendarThematicPlanShow', VCalendarThematicPlanShow);

  Vue.component('VExternalStatementSignButtons', VExternalStatementSignButtons);
  Vue.component('VExternalStatementSignModals', VExternalStatementSignModals);

  Vue.component('VStatementServiceSignButtons', VStatementSerivceSignButtons);
  Vue.component('VStatementServiceSignModals', VStatementSerivceSignModals);

  Vue.component('VGovServiceSignModals', VGovServiceSign);
  Vue.component('VEducationResultForm', VEducationResultForm);
  Vue.component('VPersonnelAdditionalInfoForm', PersonnelAdditionalInfoForm);

  Vue.component('VVideoConferencesCreateForm', VideoConferencesCreateForm);
}
