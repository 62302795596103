import { createApp, defineAsyncComponent } from 'vue';

const VJournalForm = defineAsyncComponent(() => import('../VueJS/views/VJournalForm/index'));

export default function (mixBoot) {
  if (document.getElementById('v-journal-form-app')) {
    const VueApp = createApp({});
    mixBoot(VueApp, false);
    VueApp.component('v-journal-form', VJournalForm);
    VueApp.mount('#v-journal-form-app');
    window.vJournalFormApp = VueApp;
  }
}
