import { createApp, defineAsyncComponent } from 'vue';

const VDiplomaList = defineAsyncComponent(() => import('../VueJS/views/VDiplomaList/index'));

export default function (mixBoot) {
  if (document.getElementById('v-diploma-list-app')) {
    const VueApp = createApp({});
    mixBoot(VueApp, false);
    VueApp.component('VDiplomaList', VDiplomaList);
    VueApp.mount('#v-diploma-list-app');
    window.vDiplomaListApp = VueApp;
  }
}
