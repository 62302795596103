import {
  h, ref, computed, watch, nextTick, getCurrentInstance,
} from 'vue';

import { QItem, QItemLabel, QList } from '@quasar/components/item';
import { QInput } from '@quasar/components/input';

import useDark, { useDarkProps } from '../../composables/private.use-dark/use-dark.js';
import { useFormProps, useFormAttrs, useFormInject } from '../../composables/use-form/private.use-form.js';
import useDatetime, { useDatetimeProps, useDatetimeEmits, getDayHash } from '../date/use-datetime.js';

import { createComponent } from '../../utils/private.create/create.js';
import { hSlot } from '../../utils/private.render/render.js';
import { formatDate, __splitDate } from '../../utils/date/date.js';
import { pad } from '../../utils/format/format.js';

function getCurrentTime() {
  const d = new Date();

  return {
    hour: d.getHours(),
    minute: d.getMinutes(),
    second: d.getSeconds(),
    millisecond: d.getMilliseconds(),
  };
}

export default createComponent({
  name: 'QTime',

  props: {
    ...useDarkProps,
    ...useFormProps,
    ...useDatetimeProps,

    modelValue: {
      required: true,
      validator: (val) => (typeof val === 'string' || val === null),
    },

    mask: {
      ...useDatetimeProps.mask,
      default: null,
    },

    withMinutes: {
      type: Boolean,
      default: true,
    },

    format24h: {
      type: Boolean,
      default: null,
    },

    defaultDate: {
      type: String,
      validator: (v) => /^-?[\d]+\/[0-1]\d\/[0-3]\d$/.test(v),
    },

    options: Function,
    hourOptions: Array,
    minuteOptions: Array,
    secondOptions: Array,

    withSeconds: Boolean,
    nowBtn: Boolean,
  },

  emits: useDatetimeEmits,

  setup(props, { slots, emit }) {
    const vm = getCurrentInstance();
    const { $q } = vm.proxy;

    const isDark = useDark(props, $q);
    const {
      tabindex, headerClass, getLocale, getCurrentDate,
    } = useDatetime(props, $q);

    const formAttrs = useFormAttrs(props);
    const injectFormInput = useFormInject(formAttrs);

    let draggingClockRect; let
      dragCache;

    const clockRef = ref(null);

    const mask = computed(() => getMask());
    const locale = computed(() => getLocale());

    const defaultDateModel = computed(() => getDefaultDateModel());

    const model = __splitDate(
      props.modelValue,
      mask.value, // initial mask
      locale.value, // initial locale
      props.calendar,
      defaultDateModel.value,
    );

    const inputModel = ref({
      hour: '00',
      minute: '00',
      second: '00',
    });

    const innerModel = ref(model);
    const isAM = ref(model.hour === null || model.hour < 12);

    const classes = computed(() => `sn-time sn-time--${props.landscape === true ? 'landscape' : 'portrait'}${
      isDark.value === true ? ' sn-time--dark sn-dark' : ''
    }${props.disable === true ? ' disabled' : (props.readonly === true ? ' sn-time--readonly' : '')
    }${props.bordered === true ? ' sn-time--bordered' : ''
    }${props.square === true ? ' sn-time--square sn--no-border-radius' : ''
    }${props.flat === true ? ' sn-time--flat sn--no-shadow' : ''}`);

    const computedFormat24h = computed(() => (
      props.format24h !== null
        ? props.format24h
        : $q.lang.date.format24h
    ));

    const hourInSelection = computed(() => (
      props.hourOptions !== void 0
        ? (val) => props.hourOptions.includes(val)
        : (
          props.options !== void 0
            ? (val) => props.options(val, null, null)
            : null
        )
    ));

    const minuteInSelection = computed(() => (
      props.minuteOptions !== void 0
        ? (val) => props.minuteOptions.includes(val)
        : (
          props.options !== void 0
            ? (val) => props.options(innerModel.value.hour, val, null)
            : null
        )
    ));

    const secondInSelection = computed(() => (
      props.secondOptions !== void 0
        ? (val) => props.secondOptions.includes(val)
        : (
          props.options !== void 0
            ? (val) => props.options(innerModel.value.hour, innerModel.value.minute, val)
            : null
        )
    ));
    function positions(type) {
      let start; let end; let offset = 0; let
        step = 1;
      const values = void 0;

      if (type === 'hour') {
        if (computedFormat24h.value === true) {
          start = 0;
          end = 23;
        } else {
          start = 0;
          end = 11;

          if (isAM.value === false) {
            offset = 12;
          }
        }
      } else {
        start = 0;
        end = 55;
        step = 5;
      }

      const pos = [];

      for (let val = start, index = start; val <= end; val += step, index++) {
        const
          actualVal = val + offset;
        const disable = values !== void 0 && values.includes(actualVal) === false;
        const label = type === 'hour' && val === 0
          ? (computedFormat24h.value === true ? '00' : '12')
          : String(val).length === 1 ? `0${val}` : val;

        pos.push({
          val: actualVal, index, disable, label,
        });
      }

      return pos;
    }

    watch(() => props.modelValue, (v) => {
      const model = __splitDate(
        v,
        mask.value,
        locale.value,
        props.calendar,
        defaultDateModel.value,
      );

      if (
        model.dateHash !== innerModel.value.dateHash
        || model.timeHash !== innerModel.value.timeHash
      ) {
        innerModel.value = model;

        if (model.hour !== null) {
          isAM.value = model.hour < 12;
        }
      }
    });

    watch([mask, locale], () => {
      nextTick(() => {
        updateValue();
      });
    });

    function setNow() {
      const date = {
        ...getCurrentDate(),
        ...getCurrentTime(),
      };

      updateValue(date);
      Object.assign(innerModel.value, date); // reset any pending changes to innerModel
    }

    function getValidValues(start, count, testFn) {
      const values = Array.apply(null, { length: count + 1 })
        .map((_, index) => {
          const i = index + start;
          return {
            index: i,
            val: testFn(i) === true, // force boolean
          };
        })
        .filter((v) => v.val === true)
        .map((v) => v.index);

      return {
        min: values[0],
        max: values[values.length - 1],
        values,
        threshold: count + 1,
      };
    }

    function getMask() {
      return props.calendar !== 'persian' && props.mask !== null
        ? props.mask
        : `HH${props.withMinutes === true ? ':mm' : ''}${props.withSeconds === true ? ':ss' : ''}`;
    }

    function getDefaultDateModel() {
      if (typeof props.defaultDate !== 'string') {
        const date = getCurrentDate(true);
        date.dateHash = getDayHash(date);
        return date;
      }

      return __splitDate(props.defaultDate, 'YYYY/MM/DD', void 0, props.calendar);
    }

    function setAmOnKey(e) {
      e.keyCode === 13 && setAm();
    }

    function setPmOnKey(e) {
      e.keyCode === 13 && setPm();
    }

    function setHour(hour) {
      if (innerModel.value.hour !== hour) {
        innerModel.value.hour = hour;
        verifyAndUpdate();
      }
      const tmpVal = String(hour).length === 1 ? `0${hour}` : String(hour);
      if (inputModel.value.hour !== tmpVal) {
        inputModel.value.hour = tmpVal;
      }
    }

    function setMinute(minute) {
      if (innerModel.value.minute !== minute) {
        innerModel.value.minute = minute;
        verifyAndUpdate();
      }
      const tmpVal = String(minute).length === 1 ? `0${minute}` : String(minute);
      if (inputModel.value.minute !== tmpVal) {
        inputModel.value.minute = tmpVal;
      }
    }

    function setSecond(second) {
      if (innerModel.value.second !== second) {
        innerModel.value.second = second;
        verifyAndUpdate();
      }
      const tmpVal = String(second).length === 1 ? `0${second}` : String(second);
      if (inputModel.value.second !== tmpVal) {
        inputModel.value.second = tmpVal;
      }
    }

    function setAm() {
      if (isAM.value === false) {
        isAM.value = true;

        if (innerModel.value.hour !== null) {
          innerModel.value.hour -= 12;
          verifyAndUpdate();
        }
      }
    }

    function setPm() {
      if (isAM.value === true) {
        isAM.value = false;

        if (innerModel.value.hour !== null) {
          innerModel.value.hour += 12;
          verifyAndUpdate();
        }
      }
    }

    function verifyAndUpdate() {
      if (hourInSelection.value !== null && hourInSelection.value(innerModel.value.hour) !== true) {
        innerModel.value = __splitDate();
        return;
      }

      if (props.withMinutes === true && minuteInSelection.value !== null && minuteInSelection.value(innerModel.value.minute) !== true) {
        innerModel.value.minute = null;
        innerModel.value.second = null;
        return;
      }

      if (props.withSeconds === true && secondInSelection.value !== null && secondInSelection.value(innerModel.value.second) !== true) {
        innerModel.value.second = null;
        return;
      }

      if (innerModel.value.hour === null || (props.withMinutes === true && innerModel.value.minute === null) || (props.withSeconds === true && innerModel.value.second === null)) {
        return;
      }

      updateValue();
    }

    function updateValue(obj) {
      const date = { ...innerModel.value, ...obj };

      const hour = pad(date.hour);
      const minute = props.withMinutes === true ? `:${pad(date.minute)}` : '';
      const second = props.withSeconds === true ? `:${pad(date.second)}` : '';

      const val = props.calendar === 'persian'
        ? `${hour}${minute}${second}`
        : formatDate(
          new Date(
            date.year,
            date.month === null ? null : date.month - 1,
            date.day,
            date.hour,
            date.minute,
            date.second,
            date.millisecond,
          ),
          mask.value,
          locale.value,
          date.year,
          date.timezoneOffset,
        );

      date.changed = val !== props.modelValue;
      emit('update:modelValue', val, date);
    }

    const HourInputRef = ref(null);
    const MinuteInputRef = ref(null);
    const SecondInputRef = ref(null);

    function handleInput(evt, type) {
      inputModel.value[type] = evt.length === 1 ? `0${evt}` : evt;
      let values = [];
      if (type === 'hour') {
        values = [...Array(24).keys()].map((itm) => (itm.length === 1 ? `0${itm}` : String(itm)));
      } else {
        values = [...Array(60).keys()].map((itm) => (itm.length === 1 ? `0${itm}` : String(itm)));
      }

      if (Number(evt) > Number(values[values.length - 1])) {
        handleInput(values[values.length - 1], type);
        return;
      }

      switch (type) {
        case 'hour':
          setHour(Number(evt));
          HourInputRef.value.updateInnerValue();
          if (props.withMinutes) {
            MinuteInputRef.value.focus();
          }
          break;
        case 'minute':
          setMinute(Number(evt));
          MinuteInputRef.value.updateInnerValue();
          if (props.withSeconds) {
            SecondInputRef.value.focus();
          }
          break;
        case 'second':
          setSecond(Number(evt));
          SecondInputRef.value.updateInnerValue();
          break;
        default: break;
      }
    }

    function handleInputKeyDown(evt, type) {
      if (['ArrowUp', 'ArrowDown'].includes(evt.key)) {
        evt.preventDefault();
        let values = [];
        if (type === 'hour') {
          values = [...Array(24).keys()];
        } else {
          values = [...Array(60).keys()];
        }

        const handleSet = (val) => {
          switch (type) {
            case 'hour': setHour(val); break;
            case 'minute': setMinute(val); break;
            case 'second': setSecond(val); break;
          }
        };

        const val = innerModel.value[type];
        let tmpVal = null;
        switch (evt.key) {
          case 'ArrowUp':
            tmpVal = val + 1;
            if (tmpVal > values[values.length - 1]) {
              tmpVal = values[0];
            }
            handleSet(tmpVal);
            break;
          case 'ArrowDown':
            tmpVal = val - 1;
            if (tmpVal < values[0]) {
              tmpVal = values[values.length - 1];
            }
            handleSet(tmpVal);
            break;
        }
      }
    }

    function getInput(type) {
      const current = inputModel.value[type];
      const getRef = () => {
        switch (type) {
          case 'hour': return HourInputRef;
          case 'minute': return MinuteInputRef;
          case 'second': return SecondInputRef;
          default: return undefined;
        }
      };

      const inputProps = {
        modelValue: current,
        mask: '##',
        type: 'tel',
        autofocus: type === 'hour',
        borderless: true,
        color: 'white',
        hideBottomSpace: true,
        debounce: 500,
      };
      const on = {
        'onUpdate:modelValue': (evt) => handleInput(evt, type),
        onKeydown: (evt) => handleInputKeyDown(evt, type),
        onFocus: (input) => input.target.select(),
      };
      const binds = {
        ref: getRef(),
        class: 'sn-time__input',
        ...inputProps,
        ...on,
      };
      return h(QInput, binds);
    }

    function getHeader() {
      const inputs = [
        getInput('hour'),
        props.withMinutes ? h('div', [':']) : null,
        props.withMinutes ? getInput('minute') : null,
        props.withSeconds ? h('div', [':']) : null,
        props.withSeconds ? getInput('second') : null,
      ];

      const child = [
        h('div', {
          class: 'sn-time__header-label sn--row sn--items-center sn--no-wrap',
          dir: 'ltr',
        }, inputs),
      ];

      computedFormat24h.value === false && child.push(
        h('div', {
          class: 'sn-time__header-ampm sn--column sn--items-between sn--no-wrap',
        }, [
          h('div', {
            class: `sn-time__link ${
              isAM.value === true ? 'sn-time__link--active' : 'sn--cursor-pointer'}`,
            tabindex: tabindex.value,
            onClick: setAm,
            onKeyup: setAmOnKey,
          }, 'AM'),

          h('div', {
            class: `sn-time__link ${
              isAM.value !== true ? 'sn-time__link--active' : 'sn--cursor-pointer'}`,
            tabindex: tabindex.value,
            onClick: setPm,
            onKeyup: setPmOnKey,
          }, 'PM'),
        ]),
      );

      return h('div', {
        class: `sn-time__header sn--flex sn--flex-center no-wrap ${headerClass.value}`,
      }, child);
    }

    function getList(type) {
      const current = innerModel.value[type.toLowerCase()];

      const itemProps = (pos) => ({
        clickable: true,
        active: pos.val === current,
        disable: pos.disable,
        tabindex: -1,
        onClick: () => {
          switch (type) {
            case 'hour': return setHour(pos.val);
            case 'minute': return setMinute(pos.val);
            case 'second': return setSecond(pos.val);
            default: break;
          }
        },
      });

      return h(QList, () => [
        positions(type).map((pos) => h(QItem, itemProps(pos), () => [
          h(QItemLabel, { textContent: pos.label }),
        ])),
      ]);
    }

    function getClock() {
      return h('div', {
        class: 'sn-time__content sn--col sn--relative-position',
      }, [
        h('div', {
          class: 'sn-time__container-parent s-pos-absolute-full',
        }, [
          h('div', {
            ref: clockRef,
            class: 'sn-time__container-child sn--fit sn--overflow-hidden',
          }, [
            getList('hour'),
            props.withMinutes ? getList('minute') : null,
            props.withSeconds ? getList('second') : null,
          ]),
        ]),
      ]);
    }

    // expose public method
    vm.proxy.setNow = setNow;

    return () => {
      const child = [getClock()];

      const def = hSlot(slots.default);
      def !== void 0 && child.push(
        h('div', { class: 'sn-time__actions' }, def),
      );

      if (props.name !== void 0 && props.disable !== true) {
        injectFormInput(child, 'push');
      }

      return h('div', {
        class: classes.value,
        tabindex: -1,
      }, [
        getHeader(),
        h('div', { class: 'sn-time__main sn--col' }, child),
      ]);
    };
  },
});
