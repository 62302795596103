import { defineAsyncComponent } from 'vue';
import Common from './Common';
import Views from './Views';

const VNoResults = defineAsyncComponent(() => import('@vjs/components/VNoResults'));
const TableCurriculum = defineAsyncComponent(() => import('./Views/TableCurriculum'));
const SelectUserFile = defineAsyncComponent(() => import('./Views/SelectUserFile'));
const DocumentFlowStatementCreate = defineAsyncComponent(() => import('./Views/DocumentFlow/StatementCreate'));
const DocumentFlowStatementFiles = defineAsyncComponent(() => import('./Views/DocumentFlow/StatementFiles'));
const UserFiles = defineAsyncComponent(() => import('./Views/UserFiles'));
const PostForm = defineAsyncComponent(() => import('./Views/Posts/Form'));
const PostCommentForm = defineAsyncComponent(() => import('./Views/PostComments/Form'));
const IssueForm = defineAsyncComponent(() => import('./Views/Issues/Form'));
const IssueCommentForm = defineAsyncComponent(() => import('./Views/Issues/Comments/Form'));
const ProfileForm = defineAsyncComponent(() => import('./Views/Profile/Form'));
const ProfileAvatar = defineAsyncComponent(() => import('./Views/ProfileAvatar'));
const UserStatementAdmissionForm = defineAsyncComponent(() => import('./Views/UserStatementAdmission/StatementForm'));
const UserStatementAdmissionFormFiles = defineAsyncComponent(() => import('./Views/UserStatementAdmission/FilesForm'));
const ScheduleForm = defineAsyncComponent(() => import('./Views/Schedule/Form'));
const GroupManagement = defineAsyncComponent(() => import('./Views/Group/Management'));
const DistributionContingentForm = defineAsyncComponent(() => import('./Views/DistributionContingent/Form'));
const DistributionContingentGroupForm = defineAsyncComponent(() => import('./Views/DistributionContingentGroup/Form'));
const AdditionalPeopleInfoForm = defineAsyncComponent(() => import('./Views/AdditionalPeopleInfo/Form'));
const UserGovServiceStatementForm = defineAsyncComponent(() => import('./Views/UserGovServiceStatement/StatementForm'));
const UserGovServiceStatementFormFiles = defineAsyncComponent(() => import('./Views/UserGovServiceStatement/FilesForm'));
const ExamForm = defineAsyncComponent(() => import('./Views/Exam/Form'));
const ExamTrainingSchedule = defineAsyncComponent(() => import('./Views/Exam/TrainingSchedule'));
const PersonnelForm = defineAsyncComponent(() => import('./Views/Personnel/Form'));
const LoadingForm = defineAsyncComponent(() => import('./Views/Loading/Form'));
const VTestProcess = defineAsyncComponent(() => import('./Views/TestProcess/VTestProcess.vue'));
const VStatementServiceSteps = defineAsyncComponent(() => import('./Views/VStatementServiceSteps/VStatementServiceSteps'));
const VAdditionalInfoForm = defineAsyncComponent(() => import('./Views/UserGovServiceStatement/AdditionalInfoForm'));
const VMedicalCommissionForm = defineAsyncComponent(() => import('./Views/MedicalCommission/Form'));

export default function (Vue) {
  Common(Vue);
  Views(Vue);

  Vue.component('VueTableCurriculum', TableCurriculum);
  Vue.component('VueDocumentFlowStatementCreate', DocumentFlowStatementCreate);
  Vue.component('VueDocumentFlowStatementFiles', DocumentFlowStatementFiles);
  Vue.component('VueSelectUserFile', SelectUserFile);
  Vue.component('VueUserFiles', UserFiles);
  Vue.component('VuePostForm', PostForm);
  Vue.component('VuePostCommentForm', PostCommentForm);
  Vue.component('VueIssueForm', IssueForm);
  Vue.component('VueIssueCommentForm', IssueCommentForm);
  Vue.component('VueProfileForm', ProfileForm);
  Vue.component('VueProfileAvatar', ProfileAvatar);
  Vue.component('VueUserStatementAdmissionForm', UserStatementAdmissionForm);
  Vue.component('VueUserStatementAdmissionFormFiles', UserStatementAdmissionFormFiles);
  Vue.component('VueUserGovServiceStatementForm', UserGovServiceStatementForm);
  Vue.component('VueUserGovServiceStatementFormFiles', UserGovServiceStatementFormFiles);
  Vue.component('VStatementServiceSteps', VStatementServiceSteps);
  Vue.component('VueScheduleForm', ScheduleForm);
  Vue.component('VueGroupManagement', GroupManagement);
  Vue.component('VueDistributionContingentForm', DistributionContingentForm);
  Vue.component('VueDistributionContingentGroupForm', DistributionContingentGroupForm);
  Vue.component('VueAdditionalPeopleInfoForm', AdditionalPeopleInfoForm);
  Vue.component('VueExamForm', ExamForm);
  Vue.component('VueExamTrainingSchedule', ExamTrainingSchedule);
  Vue.component('VuePersonnelForm', PersonnelForm);
  Vue.component('VNoResults', VNoResults);
  Vue.component('VTestProcess', VTestProcess);
  Vue.component('VueLoadingForm', LoadingForm);
  Vue.component('VAdditionalInfoForm', VAdditionalInfoForm);
  Vue.component('VueMedicalCommissionForm', VMedicalCommissionForm);
}
