import { defineAsyncComponent } from 'vue';

const Table = defineAsyncComponent(() => import('./Table'));
const Button = defineAsyncComponent(() => import('./Button'));
const SelectField = defineAsyncComponent(() => import('./SelectField'));
const Select2 = defineAsyncComponent(() => import('./Select2'));

export default function (Vue) {
  Vue.component('VueTable', Table);
  Vue.component('VueButton', Button);
  Vue.component('SelectField', SelectField);
  Vue.component('VueSelect2', Select2);
}
