import { createApp, defineAsyncComponent } from 'vue';

const VPassportPageView = defineAsyncComponent(() => import('../VueJS/views/VPassportPageView/index'));

export default function (mixBoot) {
  if (document.getElementById('v-passport-page-view-app')) {
    const VueApp = createApp({});
    mixBoot(VueApp, false);
    VueApp.component('VPassportPageView', VPassportPageView);
    VueApp.mount('#v-passport-page-view-app');
    window.vPassportPageViewApp = VueApp;
  }
}
