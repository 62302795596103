import _ from 'lodash';
import { Helper } from '@common/src/helpers';
import { createReactivePlugin } from '@quasar/utils/private.create/create';
import { markRaw } from 'vue';
import VueSModal from './SModal';

const SModal = createReactivePlugin({ modals: {}, checkModal: {}, _Vue: null }, {
  open(id, options) {
    if (_.get(options, 'preCheck', null) === null) {
      // SModal.modals[id] = options;
      const component = typeof options?.component === 'object' ? markRaw(options?.component) : options.component;
      SModal.modals[id] = { ...options, component };
    } else {
      SModal.checkModal = { id, options };
      SModal.checkAction(options.preCheck);
    }
  },
  async close(id) {
    const item = SModal.modals[id];
    if (item.preClose) {
      await item.preClose();
    }
    delete SModal.modals[id];
  },
  closeAll() { SModal.modals = {}; },
  checkAction(preCheck) {
    const data = {};

    const { action } = preCheck;
    let { method } = preCheck;

    if (!method) {
      method = 'post';
    }
    SModal._Vue.$globalLoading.show();

    const config = {
      responseType: 'json',
      method,
      headers: {
        'X-CHECK-ACTION': '1',
      },
    };
    config.headers['X-CSRF-TOKEN'] = window.core_project.csrfToken;

    let request;
    const formData = Helper.genFormDataArray(data);
    switch (method.toLowerCase()) {
      case 'post':
        request = SModal._Vue.$http.post(action, formData, config);
        break;
      case 'delete':
        formData.append('_method', 'DELETE');
        request = SModal._Vue.$http.post(action, formData, config);
        break;
      case 'patch':
        formData.append('_method', 'patch');
        request = SModal._Vue.$http.post(action, formData, config);
        break;
      default:
    }
    request.then(
      (response) => {
        if (Helper.handlerResponseError(response)) {
          SModal._Vue.$globalLoading.hide();
          if (response.status === 200) {
            SModal.modals[SModal.checkModal.id] = SModal.checkModal.options;
          }
        }
      },
      (response) => {
        if (response?.data?.warning) {
          SModal._Vue.$notify({ text: response?.data?.warning, type: 'warn' });
        } else if (response?.data?.notice) {
          SModal._Vue.$notify({ text: response.data.notice, type: 'success' });
        } else {
          Helper.handlerResponseError(response);
        }
        SModal._Vue.$globalLoading.hide();
      },
    );
  },
  install(_Vue) {
    SModal._Vue = _Vue;
    const handleBinding = (el, binding) => {
      const config = binding.value;
      let id = '';
      let preCheck = null;
      let component = '';
      let title = '';
      let size = '480';// 480 700 800
      let inBody = true;
      let notPersistent = false;
      let binds = {};
      let events = {};
      if (_.isString(config)) {
        id = config;
        component = config;
      } else {
        binds = config.binds || {};
        events = config.events || {};
        // eslint-disable-next-line prefer-destructuring
        id = config.id;
        // eslint-disable-next-line prefer-destructuring
        component = config.component;
        // eslint-disable-next-line prefer-destructuring
        title = config.title;
        // eslint-disable-next-line prefer-destructuring
        inBody = config.inBody;
        // eslint-disable-next-line prefer-destructuring
        notPersistent = config.notPersistent;

        preCheck = config.preCheck ?? preCheck;
        size = config.size ? String(config.size) : size;
      }

      el.addEventListener('click', (event) => {
        event.preventDefault();
        SModal.open(id, {
          size,
          title,
          inBody,
          notPersistent,
          preCheck,
          component,
          binds,
          events,
        });
      });
    };

    // eslint-disable-next-line no-param-reassign
    _Vue.config.globalProperties.$sModal = SModal;
    // eslint-disable-next-line no-param-reassign
    _Vue.sModal = SModal;
    _Vue.component('SModals', VueSModal);
    _Vue.directive('s-modal', {
      beforeMount(el, binding) {
        if (binding.value) {
          handleBinding(el, binding);
        }
      },
      updated(el, binding) {
        if (binding.value) {
          handleBinding(el, binding);
        }
      },
    });
  },
});

export default SModal;
