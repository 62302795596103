import { createApp, defineAsyncComponent } from 'vue';

const VExamQuestions = defineAsyncComponent(() => import('../VueJS/views/VExamQuestions/VExamQuestions.vue'));

export default function (mixBoot) {
  if (document.getElementById('v-exam-questions-app')) {
    const VueApp = createApp({});
    mixBoot(VueApp, false);
    VueApp.component('v-exam-questions', VExamQuestions);
    VueApp.mount('#v-exam-questions-app');
    window.vExamQuestionsApp = VueApp;
  }
}
