import { createApp, defineAsyncComponent } from 'vue';

const VCatalogListView = defineAsyncComponent(() => import('../VueJS/views/VCatalogListView'));

export default function (mixBoot) {
  if (document.getElementById('v-catalog-list-view-app')) {
    const VueApp = createApp({});
    mixBoot(VueApp, false);
    VueApp.component('VCatalogListView', VCatalogListView);
    VueApp.mount('#v-catalog-list-view-app');
    window.vCatalogListViewApp = VueApp;
  }
}
