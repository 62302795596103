import { defineAsyncComponent } from 'vue';

const JournalEditPoint = defineAsyncComponent(() => import('./Journal/JournalEditPoint'));
const JournalGenerateWeeks = defineAsyncComponent(() => import('./Journal/JournalGenerateWeeks'));
const JournalCalcResult = defineAsyncComponent(() => import('./Journal/JournalCalcResult'));
const JournalDayForm = defineAsyncComponent(() => import('./Journal/JournalDayForm'));
const Confirm = defineAsyncComponent(() => import('./Confirm'));
const ConfirmNew = defineAsyncComponent(() => import('./ConfirmNew'));
const ConfirmWithMessage = defineAsyncComponent(() => import('./ConfirmWithMessage'));
const StatementAttestationAveragePoint = defineAsyncComponent(() => import('./StatementAttestationAveragePoint'));
const SelectUserFile = defineAsyncComponent(() => import('../../views/SelectUserFile'));
const ReassignSpecialtyPlaces = defineAsyncComponent(() => import('./ReassignSpecialtyPlaces'));
const VAttendanceModal = defineAsyncComponent(() => import('./Attendance/DayAttendance'));
const VContingentAttendanceModal = defineAsyncComponent(() => import('./Attendance/ContingentAttendance'));
const VMultipleButtonsModal = defineAsyncComponent(() => import('./MultipleButtons'));
const VModalExamQuestionsImport = defineAsyncComponent(() => import('./ExamQuestions/Import'));
const VHolidaysModal = defineAsyncComponent(() => import('./Attendance/Holidays'));
const ProgramChapter = defineAsyncComponent(() => import('./CurriculumWorkingProgram/ProgramChapter'));
const ProgramLesson = defineAsyncComponent(() => import('./CurriculumWorkingProgram/ProgramLesson'));
const ProgramDuplicate = defineAsyncComponent(() => import('./CurriculumWorkingProgram/ProgramDuplicate'));
const VQuestionVisibilityModal = defineAsyncComponent(() => import('./ExamQuestions/VQuestionVisibilityModal'));
const MultiExportModal = defineAsyncComponent(() => import('./MultiExportModal'));
const VGraduatedContingentForm = defineAsyncComponent(() => import('./Contingent'));
const StatementSocialStatus = defineAsyncComponent(() => import('./StatementSocialStatus'));
const EditDiplomaMargins = defineAsyncComponent(() => import('./EditDiplomaMargins'));
const DuplicateContingentTranscript = defineAsyncComponent(() => import('./DuplicateContingentTranscript'));
const TranscriptChangePosition = defineAsyncComponent(() => import('./TranscriptChangePosition'));
const CurriculumAddSection = defineAsyncComponent(() => import('./CurriculumAddSection'));
const DormitoryRoomModal = defineAsyncComponent(() => import('./Dormitory/RoomModal'));
const DormitoryToAnotherRoomModal = defineAsyncComponent(() => import('./Dormitory/ToAnotherRoomModal'));
const SelectFileByElementModal = defineAsyncComponent(() => import('./SelectFileByElementModal'));
const StatementAppealModal = defineAsyncComponent(() => import('./StatementAppealModal'));
const VAgreementModal = defineAsyncComponent(() => import('./VAgreementModal'));
const CurriculumDeleteSection = defineAsyncComponent(() => import('./CurriculumDeleteSection'));
const VUploadFIleModal = defineAsyncComponent(() => import('./Settings/VUploadFIleModal'));
const SettingConfirmModal = defineAsyncComponent(() => import('./Settings/SettingConfirmModal'));
const ExceptionUsersModal = defineAsyncComponent(() => import('./Settings/ExceptionUsersModal'));
const VAddPracticeInfo = defineAsyncComponent(() => import('./Practice'));
const VAddPracticeDiary = defineAsyncComponent(() => import('./Practice/Diary'));
const EducationResultContingentTranscript = defineAsyncComponent(() => import('./EducationResultContingentTranscript'));
const DocumentFlow = defineAsyncComponent(() => import('./DocumentFlow'));
const CalendarThematicPlanDuplicateModal = defineAsyncComponent(() => import('./CalendarThematicPlanDuplicateModal'));
const ContingentSocialAchievementAdd = defineAsyncComponent(() => import('./ContingentSocialAchievementAdd'));
const PracticeGradeModal = defineAsyncComponent(() => import('./Practice/PracticeGradeModal'));
const PracticeCompletionModal = defineAsyncComponent(() => import('./Practice/PracticeCompletionModal'));
const DiplomaFullNameDeclensionModal = defineAsyncComponent(() => import('./DiplomaFullNameDeclensionModal'));
const EditDiplomaSubjectsCountModal = defineAsyncComponent(() => import('./Settings/EditDiplomaSubjectsCountModal'));
const JournalDayPlanDuplicate = defineAsyncComponent(() => import('./Journal/JournalDayPlanDuplicate'));
const ContingentTranscriptExportModal = defineAsyncComponent(() => import('./ContingentTranscriptExportModal'));

export default function (Vue) {
  Vue.component('v-modal-journal-edit-point', JournalEditPoint);
  Vue.component('v-modal-journal-generate-weeks', JournalGenerateWeeks);
  Vue.component('v-modal-journal-calc-result', JournalCalcResult);
  Vue.component('v-modal-journal-day-form', JournalDayForm);
  Vue.component('v-modal-confirm', Confirm);
  Vue.component('v-modal-confirm-new', ConfirmNew);
  Vue.component('v-modal-statement-attestation-average-point', StatementAttestationAveragePoint);
  Vue.component('v-modal-select-user-files', SelectUserFile);
  Vue.component('v-modal-reassign-specialty-places', ReassignSpecialtyPlaces);
  Vue.component('v-modal-confirm-with-message', ConfirmWithMessage);
  Vue.component('v-modal-attendance', VAttendanceModal);
  Vue.component('v-modal-contingent-attendance', VContingentAttendanceModal);
  Vue.component('v-modal-multi-button', VMultipleButtonsModal);
  Vue.component('v-modal-exam-questions-import', VModalExamQuestionsImport);
  Vue.component('v-holidays-modal', VHolidaysModal);
  Vue.component('v-modal-add-chapter', ProgramChapter);
  Vue.component('v-modal-add-lesson', ProgramLesson);
  Vue.component('v-modal-program-duplicate', ProgramDuplicate);
  Vue.component('v-question-visibility-modal', VQuestionVisibilityModal);
  Vue.component('v-multiple-export-modal', MultiExportModal);
  Vue.component('v-graduated-contingent-form-modal', VGraduatedContingentForm);
  Vue.component('v-statement-social-status-modal', StatementSocialStatus);
  Vue.component('v-modal-edit-diploma-margins', EditDiplomaMargins);
  Vue.component('v-modal-duplicate-contingent-transcript', DuplicateContingentTranscript);
  Vue.component('v-modal-transcript-change-position', TranscriptChangePosition);
  Vue.component('v-modal-curriculum-add-section', CurriculumAddSection);
  Vue.component('v-dormitory-room-modal', DormitoryRoomModal);
  Vue.component('v-dormitory-to-another-room-modal', DormitoryToAnotherRoomModal);
  Vue.component('v-select-file-by-element-modal', SelectFileByElementModal);
  Vue.component('v-statement-appeal-modal', StatementAppealModal);
  Vue.component('v-agreement-modal', VAgreementModal);
  Vue.component('v-modal-curriculum-delete-section', CurriculumDeleteSection);
  Vue.component('v-modal-upload-file', VUploadFIleModal);
  Vue.component('v-setting-confirm-modal', SettingConfirmModal);
  Vue.component('v-exception-user-modal', ExceptionUsersModal);
  Vue.component('v-add-practice-modal', VAddPracticeInfo);
  Vue.component('v-add-practice-diary-modal', VAddPracticeDiary);
  Vue.component('v-modal-education-result-contingent-transcript', EducationResultContingentTranscript);
  Vue.component('v-document-flow-modal', DocumentFlow);
  Vue.component('v-calendar-thematic-plan-duplicate-modal', CalendarThematicPlanDuplicateModal);
  Vue.component('v-contingent-social-achievement-add', ContingentSocialAchievementAdd);
  Vue.component('v-practice-grade-modal', PracticeGradeModal);
  Vue.component('v-practice-completion-modal', PracticeCompletionModal);
  Vue.component('v-diploma-full-name-declension-modal', DiplomaFullNameDeclensionModal);
  Vue.component('v-edit-diploma-subject-count-modal', EditDiplomaSubjectsCountModal);
  Vue.component('v-modal-journal-day-plan-duplicate', JournalDayPlanDuplicate);
  Vue.component('v-contingent-transcript-export-modal', ContingentTranscriptExportModal);
}
