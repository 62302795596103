export function getYoutubeID(url) {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return (match && match[7].length === 11) ? match[7] : false;
}

function addWrapper(el) {
  const domElementWrapper = document.createElement('div');
  domElementWrapper.classList.add('iframe-youtube');
  el.parentNode.insertBefore(domElementWrapper, el);
  domElementWrapper.appendChild(el);
}

async function loadScript(link) {
  return new Promise((res) => {
    const tagScript = document.createElement('script');
    tagScript.onload = () => {
      setTimeout(() => res(true), 1000);
    };
    tagScript.src = link;
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tagScript, firstScriptTag);
  });
}

export const youtube = async (arr) => {
  const iframeAPIURI = 'https://www.youtube.com/iframe_api';
  const scriptInserted = Array.from(document.querySelectorAll('script'))
    .filter((itm) => itm.src === iframeAPIURI).length !== 0;

  if (!scriptInserted) {
    await loadScript(iframeAPIURI);
  }

  arr.forEach((item) => {
    const domElement = document.getElementById(item.domID);
    addWrapper(domElement);
    new YT.Player(item.domID, {
      videoId: item.yID,
    });
  });
};
