import { createApp, defineAsyncComponent } from 'vue';

const VContingentMonitoring = defineAsyncComponent(() => import('../VueJS/views/VContingentMonitoring/index'));

export default function (mixBoot) {
  if (document.getElementById('v-contingent-monitoring-app')) {
    const VueApp = createApp({});
    mixBoot(VueApp, false);
    VueApp.component('v-contingent-monitoring', VContingentMonitoring);
    VueApp.mount('#v-contingent-monitoring-app');
    window.vContingentMonitoringApp = VueApp;
  }
}
