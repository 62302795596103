import _ from 'lodash';
import transportToQuestion from './transportToQuestion';

function transportToSubject(state = {}, direction = true) {
  let index = 0;
  for (let i = 0; i < state.data.length; i++) {
    const subjectIdx = _.findIndex(state.data, (o) => o.id === state.curSubjectID);
    // Расчёт индекса в зависимости от направления (true - вперёд)
    if (direction) {
      if (state.data[subjectIdx + 1] && state.data[subjectIdx + 1].leftAnswers !== 0) {
        state.curSubjectID = state.data[subjectIdx + 1].id;
        transportToQuestion(state.data[subjectIdx + 1], true, state, true);
        break;
      } else if (state.data[i] && state.data[i].leftAnswers !== 0) {
        state.curSubjectID = state.data[i].id;
        transportToQuestion(state.data[i], true, state, true);
        break;
      } else {
        index += 1;
      }
    } else if (state.data[subjectIdx - 1] && state.data[subjectIdx - 1].leftAnswers !== 0) {
      state.curSubjectID = state.data[subjectIdx - 1].id;
      transportToQuestion(state.data[subjectIdx - 1], true, state, true);
      break;
    } else if (state.data[i] && state.data[i].leftAnswers !== 0) {
      state.curSubjectID = state.data[i].id;
      transportToQuestion(state.data[i], true, state, true);
      break;
    } else {
      index += 1;
    }
  }

  if (index === state.data.length) {
    state.test.status = 'wait';
  }
}

export default transportToSubject;
