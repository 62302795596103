import { defineAsyncComponent } from 'vue';
import Common from './Common';
import Views from './Views';

const VNoResults = defineAsyncComponent(() => import('@vjs/components/VNoResults'));
const TableCurriculum = defineAsyncComponent(() => import('./Views/TableCurriculum'));
const SelectUserFile = defineAsyncComponent(() => import('./Views/SelectUserFile'));
const DocumentFlowStatementCreate = defineAsyncComponent(() => import('./Views/DocumentFlow/StatementCreate'));
const DocumentFlowStatementFiles = defineAsyncComponent(() => import('./Views/DocumentFlow/StatementFiles'));
const UserFiles = defineAsyncComponent(() => import('./Views/UserFiles'));
const PostForm = defineAsyncComponent(() => import('./Views/Posts/Form'));
const PostCommentForm = defineAsyncComponent(() => import('./Views/PostComments/Form'));
const IssueForm = defineAsyncComponent(() => import('./Views/Issues/Form'));
const IssueCommentForm = defineAsyncComponent(() => import('./Views/Issues/Comments/Form'));
const ProfileForm = defineAsyncComponent(() => import('./Views/Profile/Form'));
const ProfileAvatar = defineAsyncComponent(() => import('./Views/ProfileAvatar'));
const UserStatementAdmissionForm = defineAsyncComponent(() => import('./Views/UserStatementAdmission/StatementForm'));
const UserStatementAdmissionFormFiles = defineAsyncComponent(() => import('./Views/UserStatementAdmission/FilesForm'));
const ScheduleForm = defineAsyncComponent(() => import('./Views/Schedule/Form'));
const GroupManagement = defineAsyncComponent(() => import('./Views/Group/Management'));
const DistributionContingentForm = defineAsyncComponent(() => import('./Views/DistributionContingent/Form'));
const DistributionContingentGroupForm = defineAsyncComponent(() => import('./Views/DistributionContingentGroup/Form'));
const AdditionalPeopleInfoForm = defineAsyncComponent(() => import('./Views/AdditionalPeopleInfo/Form'));
const UserGovServiceStatementForm = defineAsyncComponent(() => import('./Views/UserGovServiceStatement/StatementForm'));
const UserGovServiceStatementFormFiles = defineAsyncComponent(() => import('./Views/UserGovServiceStatement/FilesForm'));
const ExamForm = defineAsyncComponent(() => import('./Views/Exam/Form'));
const ExamTrainingSchedule = defineAsyncComponent(() => import('./Views/Exam/TrainingSchedule'));
const PersonnelForm = defineAsyncComponent(() => import('./Views/Personnel/Form'));
const LoadingForm = defineAsyncComponent(() => import('./Views/Loading/Form'));
const VTestProcess = defineAsyncComponent(() => import('./Views/TestProcess/VTestProcess.vue'));
const VStatementServiceSteps = defineAsyncComponent(() => import('./Views/VStatementServiceSteps/VStatementServiceSteps'));
const VAdditionalInfoForm = defineAsyncComponent(() => import('./Views/UserGovServiceStatement/AdditionalInfoForm'));
const VMedicalCommissionForm = defineAsyncComponent(() => import('./Views/MedicalCommission/Form'));

export default function (Vue) {
  Common(Vue);
  Views(Vue);

  Vue.component('vue-table-curriculum', TableCurriculum);
  Vue.component('vue-document-flow-statement-create', DocumentFlowStatementCreate);
  Vue.component('vue-document-flow-statement-files', DocumentFlowStatementFiles);
  Vue.component('vue-select-user-file', SelectUserFile);
  Vue.component('vue-user-files', UserFiles);
  Vue.component('vue-post-form', PostForm);
  Vue.component('vue-post-comment-form', PostCommentForm);
  Vue.component('vue-issue-form', IssueForm);
  Vue.component('vue-issue-comment-form', IssueCommentForm);
  Vue.component('vue-profile-form', ProfileForm);
  Vue.component('vue-profile-avatar', ProfileAvatar);
  Vue.component('vue-user-statement-admission-form', UserStatementAdmissionForm);
  Vue.component('vue-user-statement-admission-form-files', UserStatementAdmissionFormFiles);
  Vue.component('vue-user-gov-service-statement-form', UserGovServiceStatementForm);
  Vue.component('vue-user-gov-service-statement-form-files', UserGovServiceStatementFormFiles);
  Vue.component('v-statement-service-steps', VStatementServiceSteps);
  Vue.component('vue-schedule-form', ScheduleForm);
  Vue.component('vue-group-management', GroupManagement);
  Vue.component('vue-distribution-contingent-form', DistributionContingentForm);
  Vue.component('vue-distribution-contingent-group-form', DistributionContingentGroupForm);
  Vue.component('vue-additional-people-info-form', AdditionalPeopleInfoForm);
  Vue.component('vue-exam-form', ExamForm);
  Vue.component('vue-exam-training-schedule', ExamTrainingSchedule);
  Vue.component('vue-personnel-form', PersonnelForm);
  Vue.component('v-no-results', VNoResults);
  Vue.component('v-test-process', VTestProcess);
  Vue.component('vue-loading-form', LoadingForm);
  Vue.component('v-additional-info-form', VAdditionalInfoForm);
  Vue.component('vue-medical-commission-form', VMedicalCommissionForm);
}
