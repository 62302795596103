import { createApp, defineAsyncComponent } from 'vue';

const VDocFlowForm = defineAsyncComponent(() => import('../VueJS/views/VDocFlowForm/index'));

export default function (mixBoot) {
  if (document.getElementById('v-doc-flow-form-app')) {
    const VueApp = createApp({});
    mixBoot(VueApp, false);
    VueApp.component('v-doc-flow-form', VDocFlowForm);
    VueApp.mount('#v-doc-flow-form-app');
    window.vDocFlowFormApp = VueApp;
  }
}
