import { createApp, defineAsyncComponent } from 'vue';

const VLoginForm = defineAsyncComponent(() => import('@common/views/VLoginForm'));

export default function (mixBoot) {
  if (document.getElementById('v-login-form-app')) {
    const VueApp = createApp({});
    mixBoot(VueApp, false);
    VueApp.component('VLoginForm', VLoginForm);
    VueApp.mount('#v-login-form-app');
    window.vLoginFormApp = VueApp;
  }
}
