import _ from 'lodash';

export default {
  // Возвращает статус режима "Загрузка"
  LOADING(state) {
    return state.loading;
  },

  // Возвращает текущий предмет
  CURRENT_SUBJECT(state) {
    const curSubject = _.find(state.data, (o) => o.id === state.curSubjectID);
    return curSubject || {};
  },

  // Возвращает текущий вопрос
  CURRENT_QUESTION(state, getters) {
    const curQuestion = _.find(
      getters.CURRENT_SUBJECT.questions,
      (o) => o.id === getters.CURRENT_SUBJECT.curQuestionID,
    );
    return curQuestion || {};
  },

  // Возвращает кол-во вопросов
  COUNT_QUESTIONS(state, getters) {
    if (getters.CURRENT_SUBJECT.questions) {
      return getters.CURRENT_SUBJECT.questions.length;
    }
    return 0;
  },

  // Возвращает информацию по текущему тестированию
  TEST(state) {
    return state.test;
  },

  QUIZ_ID(state) {
    return state.quizId;
  },

  // Возвращает массив предметов
  SUBJECTS(state) {
    return state.data || [];
  },

  // Возвращает выбранный вариант ответа
  SELECTED_ANSWER(state, getters) {
    return _.find(getters.CURRENT_QUESTION.answers, (o) => o.checked === true);
  },

  // Возвращает текущую страницу на карте ответов
  CURRENT_QMAP_NUM(state) {
    return state.curQuestionsMapPage;
  },

  // Возвращает кол-во неотвеченных вопросов в текущем предмете
  CURRENT_LEFT_ANSWERS(state, getters) {
    return getters.CURRENT_SUBJECT.leftAnswers;
  },

  // Возвращает кол-во неотвеченных вопросов со всех предметов
  LEFT_ANSWERS(state) {
    return state.data.reduce((acc, item) => item.leftAnswers, 0);
  },
};
