import { defineAsyncComponent } from 'vue';

const Table = defineAsyncComponent(() => import('./Table'));
const Button = defineAsyncComponent(() => import('./Button'));
const SelectField = defineAsyncComponent(() => import('./SelectField'));
const Select2 = defineAsyncComponent(() => import('./Select2'));

export default function (Vue) {
  Vue.component('vue-table', Table);
  Vue.component('vue-button', Button);
  Vue.component('select-field', SelectField);
  Vue.component('vue-select2', Select2);
}
