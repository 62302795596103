import { createApp, defineAsyncComponent } from 'vue';

const VAdmissionResultList = defineAsyncComponent(() => import('../VueJS/views/VAdmissionResult/List/index'));
const VAdmissionContestResultList = defineAsyncComponent(() => import('../VueJS/views/VAdmissionResult/ContestResultList'));

export default function (mixBoot) {
  if (document.getElementById('v-admission-result-list-app')) {
    const VueApp = createApp({});
    mixBoot(VueApp, false);
    VueApp.component('v-admission-result-list', VAdmissionResultList);
    VueApp.component('v-admission-contest-result-list', VAdmissionContestResultList);
    VueApp.mount('#v-admission-result-list-app');
    window.vAdmissionResultListApp = VueApp;
  }
}
