import { createStore } from 'vuex';
import library from './library';
import examQuestions from './examQuestions';
import vSteps from './VSteps';
import vJournal from './vJournal';
import VAttendance from './VAttendance';
import VCurriculumProgram from './VCurriculumProgram';
import VQuestionnaire from './VQuestionnaire';
import vTestProcess from './vTestProcess';

export default createStore({
  modules: {
    library,
    examQuestions,
    vSteps,
    vJournal,
    VAttendance,
    VCurriculumProgram,
    VQuestionnaire,
    vTestProcess,
  },
});
