import { defineAsyncComponent } from 'vue';

import {
  QSlideTransition, QField, QTooltip, QBtn, QBtnDropdown, QInput, QSelect,
} from '@quasar/components';

const VFormInform = defineAsyncComponent(() => import('@vjs/components/VFormInform'));
const VTwoFieldWrapper = defineAsyncComponent(() => import('@common/components/VTwoFieldWrapper'));
const EgovSign = defineAsyncComponent(() => import('@common/components/Modals/EgovSign'));

export default function (Vue) {
  Vue.component('QSlideTransition', QSlideTransition);
  Vue.component('QField', QField);
  Vue.component('QTooltip', QTooltip);
  Vue.component('QBtn', QBtn);
  Vue.component('QBtnDropdown', QBtnDropdown);
  Vue.component('QInput', QInput);
  Vue.component('QSelect', QSelect);

  Vue.component('VFormInform', VFormInform);
  Vue.component('VTwoFieldWrapper', VTwoFieldWrapper);

  // Modal components
  Vue.component('VModalEgovSign', EgovSign);
}
