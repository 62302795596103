import CKEditor from './ckeditor-vue.vue';
import 'moment/dist/locale/ru';
import 'moment/dist/locale/kk';
import quasarApp from '@quasar/install';
import Translator from '@common/plugins/Translator';
import Inputmask from 'inputmask';
import axios from 'axios';
import Notify from '@vjs/components/Notify/Notify';
import Notifications from '@vjs/plugins/Notification';
import { eventBus } from '@common/mixins';
import Viewer from 'v-viewer';
import GlobalLoading from './GlobalLoading';
import UserEventBus from './UserEventBus';
import VueResource from './VueResource';
import SModal from './SModal';

export default function (Vue) {
  // Выключаем "клики" на клике по десктопному подменю
  // (чтобы не закрывался дропдаун)
  $(() => {
    $('.s-dropdown__link--multi').click((e) => {
      e.stopPropagation();
    });
  });

  const token = document.head.querySelector('meta[name="csrf-token"]');
  if (token) {
    window.$.ajaxSetup({
      headers: { 'X-CSRF-TOKEN': token.content },
    });
  } else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
  }
  // endregion
  if (window.core_project) {
    axios.defaults.headers.common = {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN': window.core_project.csrfToken || '',
      'Accept-Language': window.core_project.locale,
    };
  } else {
    // eslint-disable-next-line no-console
    console.log('No window.core_project! You\'re probably testing static components');
  }

  const inputmaskPlugin = {
    install(_Vue, options) {
      _Vue.directive('mask', {
        created(el, binding) {
          setTimeout(() => { Inputmask(binding.value).mask(el); });
        },
      });
    },
  };

  Vue.config.compilerOptions.whitespace = 'preserve';
  Vue.config.globalProperties.$axios = axios;
  Vue.config.globalProperties.$eventHub = eventBus;
  Vue.use(Translator);
  Vue.use(quasarApp);
  Vue.use(VueResource);
  Vue.use(SModal);
  Vue.use(UserEventBus);
  Vue.use(GlobalLoading);
  Vue.use(inputmaskPlugin);
  Vue.use(Notifications);
  Vue.use(Viewer);
  Vue.component('Notify', Notify);
  Vue.component('Ckeditor', CKEditor);
  window.$axios = axios;
  window.axios = axios;
}
