import { createApp, defineAsyncComponent } from 'vue';

const VExamTickets = defineAsyncComponent(() => import('../VueJS/views/VExamTickets/VExamTickets.vue'));

export default function (mixBoot) {
  if (document.getElementById('v-exam-tickets-app')) {
    const VueApp = createApp({});
    mixBoot(VueApp, false);
    VueApp.component('VExamTickets', VExamTickets);
    VueApp.mount('#v-exam-tickets-app');
    window.vExamTicketsApp = VueApp;
  }
}
