import { defineAsyncComponent } from 'vue';

const RegistrationPhoneForm = defineAsyncComponent(() => import('@common/views/FormRegistrationSms'));
const VOrganizationProfile = defineAsyncComponent(() => import('@common/views/VOrganizationProfile'));
const VOrganizationEditForm = defineAsyncComponent(() => import('@common/views/VOrganizationEditForm'));
const VOrganizationInfo = defineAsyncComponent(() => import('@common/views/VOrganizationInfo'));
const NewTableWithFilters = defineAsyncComponent(() => import('@common/views/NewTableWithFilters'));
const VFeedbackForm = defineAsyncComponent(() => import('@vjs/views/VFeedbackForm'));
const VContestView = defineAsyncComponent(() => import('@app_college/views/VContestView'));
const VNameBookView = defineAsyncComponent(() => import('@app_college/views/VNameBookView'));
const VAdmissionResultProfessionsList = defineAsyncComponent(() => import('@app_college/views/VAdmissionResult/ProfessionsList'));
const VAdmissionResultList = defineAsyncComponent(() => import('@app_college/views/VAdmissionResult/ContestResultList'));
const VAdmissionMonitoringView = defineAsyncComponent(() => import('@app_college/views/VAdmissionMonitoringView'));
const VJournalFillingActivity = defineAsyncComponent(() => import('@app_college/views/VJournalFillingActivity'));
const JitsiConference = defineAsyncComponent(() => import('./JitsiConference'));
const TrainingScheduleShow = defineAsyncComponent(() => import('./TrainingSchedule/Show'));
const VExternalStatementsGradesView = defineAsyncComponent(() => import('./VExternalStatementsGradesView'));
const VHomeworkQuizResult = defineAsyncComponent(() => import('./VHomeworkQuizResult'));
const VContingentPerformanceBook = defineAsyncComponent(() => import('./VContingentPerformanceBook'));
const VOrganizationDisciplinesTable = defineAsyncComponent(() => import('./VOrganizationDisciplinesTable'));
const VOrganizationDisciplinesAddSubjectsForm = defineAsyncComponent(() => import('./VOrganizationDisciplinesAddSubjectsForm'));
const VJournal = defineAsyncComponent(() => import('./VJournal'));
const VStatementSignButtons = defineAsyncComponent(() => import('./VStatementSignButtons'));
const VGrantRefusalsList = defineAsyncComponent(() => import('./VGrantRefusalsList'));
const VStudentTranscript = defineAsyncComponent(() => import('./VStudentTranscript'));
const VEducationModuleForm = defineAsyncComponent(() => import('./VEducationModuleForm'));
const VJournalPlanForm = defineAsyncComponent(() => import('./VJournalPlanForm'));
const VTimeSheetView = defineAsyncComponent(() => import('./VTimeSheetView'));
const VCurriculumWorkingProgramForm = defineAsyncComponent(() => import('./VCurriculumWorkingProgram/Form'));
const VCurriculumWorkingProgramView = defineAsyncComponent(() => import('./VCurriculumWorkingProgram/View'));
const VDocumentFlowStatement = defineAsyncComponent(() => import('./DocumentFlow/Statement'));
const SignButton = defineAsyncComponent(() => import('./DocumentFlow/SignButtons'));
const VQuestionnaireForm = defineAsyncComponent(() => import('./VQuestionnaire/Form'));
const VQuestionsList = defineAsyncComponent(() => import('./VQuestionnaire/VQuestions/VQuestionsList.vue'));
const VQuestionnaire = defineAsyncComponent(() => import('./VQuestionnaire/Questionnaire'));
const VQuestionnaireProcess = defineAsyncComponent(() => import('./VQuestionnaire/Process'));
const VAdmissionStructure = defineAsyncComponent(() => import('./VAdmissionStructure'));
const VDormitoryForm = defineAsyncComponent(() => import('./VDormitory/Form'));
const VDormitoryList = defineAsyncComponent(() => import('./VDormitory/List'));
const VDormitoryRecords = defineAsyncComponent(() => import('./VDormitory/Records'));
const VDormitoryStatements = defineAsyncComponent(() => import('./VDormitory/Statements'));
const VDormitoryButtons = defineAsyncComponent(() => import('./VDormitory/Buttons'));
const VContingentPersonalInfo = defineAsyncComponent(() => import('./VContingentPersonalInfo'));
const VStatementAppeal = defineAsyncComponent(() => import('./VStatementAppeal'));
const VEducationResultsTable = defineAsyncComponent(() => import('./VEducationResultsTable'));
const VSetting = defineAsyncComponent(() => import('./VSetting'));
const VEnterprise = defineAsyncComponent(() => import('./VEnterprise'));
const VEnterpriseForm = defineAsyncComponent(() => import('./VEnterprise/Form'));
const VEnterprisePersonnel = defineAsyncComponent(() => import('./VEnterprise/Personnel'));
const VEnterprisePersonnelForm = defineAsyncComponent(() => import('./VEnterprise/Personnel/Form'));
const VPracticeForm = defineAsyncComponent(() => import('./VPractice/Form'));
const VPractice = defineAsyncComponent(() => import('./VPractice'));
const VAdmissionStatementSearch = defineAsyncComponent(() => import('./VAdmissionStatementSearch'));
const VExamQuizResult = defineAsyncComponent(() => import('./VExamQuizResult'));
const VStudentCard = defineAsyncComponent(() => import('./VStudentCard'));
const VContingentSocialAchievement = defineAsyncComponent(() => import('./VContingentSocialAchievement'));
const VLiveKitView = defineAsyncComponent(() => import('./VLiveKitView'));
const VGroupChat = defineAsyncComponent(() => import('@common/components/VGroupChat'));

export default function (Vue) {
  Vue.component('vue-registration-phone-form', RegistrationPhoneForm);
  Vue.component('v-feedback-form', VFeedbackForm);
  Vue.component('vue-jitsi-conference', JitsiConference);
  Vue.component('vue-external-statements-grades', VExternalStatementsGradesView);
  Vue.component('vue-training-schedule-show', TrainingScheduleShow);

  Vue.component('v-organization-profile', VOrganizationProfile);
  Vue.component('v-organization-info', VOrganizationInfo);
  Vue.component('v-organization-edit-form', VOrganizationEditForm);
  Vue.component('v-homework-quiz-result', VHomeworkQuizResult);
  Vue.component('v-contest-view', VContestView);
  Vue.component('v-name-book-view', VNameBookView);
  Vue.component('v-contingent-performance-book', VContingentPerformanceBook);
  Vue.component('v-organization-disciplines-table', VOrganizationDisciplinesTable);
  Vue.component('v-organization-disciplines-add-subjects-form', VOrganizationDisciplinesAddSubjectsForm);
  Vue.component('v-statement-sign-buttons', VStatementSignButtons);
  Vue.component('v-admission-monitoring-view', VAdmissionMonitoringView);
  Vue.component('v-journal', VJournal);
  Vue.component('vue-new-table-with-filters', NewTableWithFilters);
  Vue.component('v-grant-refusals-list', VGrantRefusalsList);
  Vue.component('v-admission-result-professions-list', VAdmissionResultProfessionsList);
  Vue.component('v-admission-contest-result-list', VAdmissionResultList);
  Vue.component('v-student-transcript', VStudentTranscript);
  Vue.component('v-education-module-form', VEducationModuleForm);
  Vue.component('v-journal-plan-form', VJournalPlanForm);
  Vue.component('v-time-sheet-view', VTimeSheetView);
  Vue.component('v-curriculum-working-program', VCurriculumWorkingProgramForm);
  Vue.component('v-curriculum-working-program-view', VCurriculumWorkingProgramView);
  Vue.component('v-document-flow-statement', VDocumentFlowStatement);
  Vue.component('v-sign-button', SignButton);
  Vue.component('v-questionnaire-form', VQuestionnaireForm);
  Vue.component('v-question-list', VQuestionsList);
  Vue.component('v-questionnaire', VQuestionnaire);
  Vue.component('v-questionnaire-process', VQuestionnaireProcess);
  Vue.component('v-admission-structure', VAdmissionStructure);
  Vue.component('v-dormitory-form', VDormitoryForm);
  Vue.component('v-dormitory-list', VDormitoryList);
  Vue.component('v-dormitory-buttons', VDormitoryButtons);
  Vue.component('v-contingent-personal-info', VContingentPersonalInfo);
  Vue.component('v-statement-appeal-view', VStatementAppeal);
  Vue.component('v-dormitory-records', VDormitoryRecords);
  Vue.component('v-dormitory-statement', VDormitoryStatements);
  Vue.component('v-education-results-table', VEducationResultsTable);
  Vue.component('v-setting', VSetting);
  Vue.component('v-journal-filling-activity', VJournalFillingActivity);
  Vue.component('v-enterprise-list', VEnterprise);
  Vue.component('v-enterprise-form', VEnterpriseForm);
  Vue.component('v-enterprise-personnel-list', VEnterprisePersonnel);
  Vue.component('v-enterprise-personnel-form', VEnterprisePersonnelForm);
  Vue.component('v-practice-form', VPracticeForm);
  Vue.component('v-practice-view', VPractice);
  Vue.component('v-admission-statement-search', VAdmissionStatementSearch);
  Vue.component('v-student-card', VStudentCard);
  Vue.component('v-exam-quiz-result', VExamQuizResult);
  Vue.component('v-contingent-social-achievement', VContingentSocialAchievement);
  Vue.component('v-live-kit-view', VLiveKitView);
  Vue.component('v-group-chat', VGroupChat);
}
