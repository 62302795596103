export default {
  apiConfig: (state) => state.apiConfig,
  cans: (state) => state.cans,
  remarks: (state) => state.remarks,
  journalTeachers: (state) => state.journalTeachers,
  infoTable: (state) => state.infoTable,
  journalDays: (state) => state.journalDays,
  students: (state) => state.students,
  refs: (state) => state.refs,

  showRetiredStudents: (state) => state.showRetiredStudents,
  currentSemester: (state) => state.currentSemester,
  currentFilter: (state) => state.currentFilter,
  typeOfEvaluation: (state) => state.typeOfEvaluation,
  colorsArr: (state) => state.colorsArr,
  hasCtp: (state) => state.hasCtp,
  hasCwp: (state) => state.hasCwp,
  journalId: (state) => state.journalId,
  makeJournalUrl: (state) => state.makeJournalUrl,

  isRemarksTableActive: (state) => state.isRemarksTableActive,
  isModule: (state) => state.isModule,
  withResult: (state) => state.withResult,
  isPersonal: (state) => state.isPersonal,
  isStudent: (state) => state.isStudent,
  status: (state) => state.status,
};
