import { defineAsyncComponent } from 'vue';

const RegistrationPhoneForm = defineAsyncComponent(() => import('@common/views/FormRegistrationSms'));
const VOrganizationProfile = defineAsyncComponent(() => import('@common/views/VOrganizationProfile'));
const VOrganizationEditForm = defineAsyncComponent(() => import('@common/views/VOrganizationEditForm'));
const VOrganizationInfo = defineAsyncComponent(() => import('@common/views/VOrganizationInfo'));
const NewTableWithFilters = defineAsyncComponent(() => import('@common/views/NewTableWithFilters'));
const VFeedbackForm = defineAsyncComponent(() => import('@vjs/views/VFeedbackForm'));
const VContestView = defineAsyncComponent(() => import('@app_college/views/VContestView'));
const VNameBookView = defineAsyncComponent(() => import('@app_college/views/VNameBookView'));
const VAdmissionResultProfessionsList = defineAsyncComponent(() => import('@app_college/views/VAdmissionResult/ProfessionsList'));
const VAdmissionResultList = defineAsyncComponent(() => import('@app_college/views/VAdmissionResult/ContestResultList'));
const VAdmissionMonitoringView = defineAsyncComponent(() => import('@app_college/views/VAdmissionMonitoringView'));
const VJournalFillingActivity = defineAsyncComponent(() => import('@app_college/views/VJournalFillingActivity'));
const JitsiConference = defineAsyncComponent(() => import('./JitsiConference'));
const TrainingScheduleShow = defineAsyncComponent(() => import('./TrainingSchedule/Show'));
const VExternalStatementsGradesView = defineAsyncComponent(() => import('./VExternalStatementsGradesView'));
const VHomeworkQuizResult = defineAsyncComponent(() => import('./VHomeworkQuizResult'));
const VContingentPerformanceBook = defineAsyncComponent(() => import('./VContingentPerformanceBook'));
const VOrganizationDisciplinesTable = defineAsyncComponent(() => import('./VOrganizationDisciplinesTable'));
const VOrganizationDisciplinesAddSubjectsForm = defineAsyncComponent(() => import('./VOrganizationDisciplinesAddSubjectsForm'));
const VJournal = defineAsyncComponent(() => import('./VJournal'));
const VStatementSignButtons = defineAsyncComponent(() => import('./VStatementSignButtons'));
const VGrantRefusalsList = defineAsyncComponent(() => import('./VGrantRefusalsList'));
const VStudentTranscript = defineAsyncComponent(() => import('./VStudentTranscript'));
const VEducationModuleForm = defineAsyncComponent(() => import('./VEducationModuleForm'));
const VJournalPlanForm = defineAsyncComponent(() => import('./VJournalPlanForm'));
const VTimeSheetView = defineAsyncComponent(() => import('./VTimeSheetView'));
const VCurriculumWorkingProgramForm = defineAsyncComponent(() => import('./VCurriculumWorkingProgram/Form'));
const VCurriculumWorkingProgramView = defineAsyncComponent(() => import('./VCurriculumWorkingProgram/View'));
const VDocumentFlowStatement = defineAsyncComponent(() => import('./DocumentFlow/Statement'));
const SignButton = defineAsyncComponent(() => import('./DocumentFlow/SignButtons'));
const VQuestionnaireForm = defineAsyncComponent(() => import('./VQuestionnaire/Form'));
const VQuestionsList = defineAsyncComponent(() => import('./VQuestionnaire/VQuestions/VQuestionsList.vue'));
const VQuestionnaire = defineAsyncComponent(() => import('./VQuestionnaire/Questionnaire'));
const VQuestionnaireProcess = defineAsyncComponent(() => import('./VQuestionnaire/Process'));
const VAdmissionStructure = defineAsyncComponent(() => import('./VAdmissionStructure'));
const VDormitoryForm = defineAsyncComponent(() => import('./VDormitory/Form'));
const VDormitoryList = defineAsyncComponent(() => import('./VDormitory/List'));
const VDormitoryRecords = defineAsyncComponent(() => import('./VDormitory/Records'));
const VDormitoryStatements = defineAsyncComponent(() => import('./VDormitory/Statements'));
const VDormitoryButtons = defineAsyncComponent(() => import('./VDormitory/Buttons'));
const VContingentPersonalInfo = defineAsyncComponent(() => import('./VContingentPersonalInfo'));
const VStatementAppeal = defineAsyncComponent(() => import('./VStatementAppeal'));
const VEducationResultsTable = defineAsyncComponent(() => import('./VEducationResultsTable'));
const VSetting = defineAsyncComponent(() => import('./VSetting'));
const VEnterprise = defineAsyncComponent(() => import('./VEnterprise'));
const VEnterpriseForm = defineAsyncComponent(() => import('./VEnterprise/Form'));
const VEnterprisePersonnel = defineAsyncComponent(() => import('./VEnterprise/Personnel'));
const VEnterprisePersonnelForm = defineAsyncComponent(() => import('./VEnterprise/Personnel/Form'));
const VPracticeForm = defineAsyncComponent(() => import('./VPractice/Form'));
const VPractice = defineAsyncComponent(() => import('./VPractice'));
const VAdmissionStatementSearch = defineAsyncComponent(() => import('./VAdmissionStatementSearch'));
const VExamQuizResult = defineAsyncComponent(() => import('./VExamQuizResult'));
const VStudentCard = defineAsyncComponent(() => import('./VStudentCard'));
const VContingentSocialAchievement = defineAsyncComponent(() => import('./VContingentSocialAchievement'));
const VLiveKitView = defineAsyncComponent(() => import('./VLiveKitView'));
const VGroupChat = defineAsyncComponent(() => import('@common/components/VGroupChat'));

export default function (Vue) {
  Vue.component('VueRegistrationPhoneForm', RegistrationPhoneForm);
  Vue.component('VFeedbackForm', VFeedbackForm);
  Vue.component('VueJitsiConference', JitsiConference);
  Vue.component('VueExternalStatementsGrades', VExternalStatementsGradesView);
  Vue.component('VueTrainingScheduleShow', TrainingScheduleShow);

  Vue.component('VOrganizationProfile', VOrganizationProfile);
  Vue.component('VOrganizationInfo', VOrganizationInfo);
  Vue.component('VOrganizationEditForm', VOrganizationEditForm);
  Vue.component('VHomeworkQuizResult', VHomeworkQuizResult);
  Vue.component('VContestView', VContestView);
  Vue.component('VNameBookView', VNameBookView);
  Vue.component('VContingentPerformanceBook', VContingentPerformanceBook);
  Vue.component('VOrganizationDisciplinesTable', VOrganizationDisciplinesTable);
  Vue.component('VOrganizationDisciplinesAddSubjectsForm', VOrganizationDisciplinesAddSubjectsForm);
  Vue.component('VStatementSignButtons', VStatementSignButtons);
  Vue.component('VAdmissionMonitoringView', VAdmissionMonitoringView);
  Vue.component('VJournal', VJournal);
  Vue.component('VueNewTableWithFilters', NewTableWithFilters);
  Vue.component('VGrantRefusalsList', VGrantRefusalsList);
  Vue.component('VAdmissionResultProfessionsList', VAdmissionResultProfessionsList);
  Vue.component('VAdmissionContestResultList', VAdmissionResultList);
  Vue.component('VStudentTranscript', VStudentTranscript);
  Vue.component('VEducationModuleForm', VEducationModuleForm);
  Vue.component('VJournalPlanForm', VJournalPlanForm);
  Vue.component('VTimeSheetView', VTimeSheetView);
  Vue.component('VCurriculumWorkingProgram', VCurriculumWorkingProgramForm);
  Vue.component('VCurriculumWorkingProgramView', VCurriculumWorkingProgramView);
  Vue.component('VDocumentFlowStatement', VDocumentFlowStatement);
  Vue.component('VSignButton', SignButton);
  Vue.component('VQuestionnaireForm', VQuestionnaireForm);
  Vue.component('VQuestionList', VQuestionsList);
  Vue.component('VQuestionnaire', VQuestionnaire);
  Vue.component('VQuestionnaireProcess', VQuestionnaireProcess);
  Vue.component('VAdmissionStructure', VAdmissionStructure);
  Vue.component('VDormitoryForm', VDormitoryForm);
  Vue.component('VDormitoryList', VDormitoryList);
  Vue.component('VDormitoryButtons', VDormitoryButtons);
  Vue.component('VContingentPersonalInfo', VContingentPersonalInfo);
  Vue.component('VStatementAppealView', VStatementAppeal);
  Vue.component('VDormitoryRecords', VDormitoryRecords);
  Vue.component('VDormitoryStatement', VDormitoryStatements);
  Vue.component('VEducationResultsTable', VEducationResultsTable);
  Vue.component('VSetting', VSetting);
  Vue.component('VJournalFillingActivity', VJournalFillingActivity);
  Vue.component('VEnterpriseList', VEnterprise);
  Vue.component('VEnterpriseForm', VEnterpriseForm);
  Vue.component('VEnterprisePersonnelList', VEnterprisePersonnel);
  Vue.component('VEnterprisePersonnelForm', VEnterprisePersonnelForm);
  Vue.component('VPracticeForm', VPracticeForm);
  Vue.component('VPracticeView', VPractice);
  Vue.component('VAdmissionStatementSearch', VAdmissionStatementSearch);
  Vue.component('VStudentCard', VStudentCard);
  Vue.component('VExamQuizResult', VExamQuizResult);
  Vue.component('VContingentSocialAchievement', VContingentSocialAchievement);
  Vue.component('VLiveKitView', VLiveKitView);
  Vue.component('VGroupChat', VGroupChat);
}
