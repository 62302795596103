import { createApp, defineAsyncComponent } from 'vue';

const VMonitoringList = defineAsyncComponent(() => import('@vjs/balabaqsha/views/Monitorings/VMonitoringList'));

export default function (mixBoot) {
  if (document.getElementById('v-monitoring-list-app')) {
    const VueApp = createApp({});
    mixBoot(VueApp, false);
    VueApp.component('VMonitoringList', VMonitoringList);
    VueApp.mount('#v-monitoring-list-app');
    window.vMonitoringListApp = VueApp;
  }
}
