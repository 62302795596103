import { createApp, defineAsyncComponent } from 'vue';

const VLibrary = defineAsyncComponent(() => import('../VueJS/views/VLibrary/VLibrary.vue'));

export default function (mixBoot) {
  if (document.getElementById('v-library-app')) {
    const VueApp = createApp({});
    mixBoot(VueApp, false);
    VueApp.component('v-library', VLibrary);
    VueApp.mount('#v-library-app');
    window.vLibraryApp = VueApp;
  }
}
