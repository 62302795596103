import { createApp } from 'vue';

export default function (mixBoot) {
  [
    'v-footer-app',
    'cl-vue-container',
  ]
    .forEach((element) => {
      if (document.getElementById(element)) {
        const VueApp = createApp({});
        mixBoot(VueApp);
        VueApp.mount(`#${element}`);
      }
    });
}
