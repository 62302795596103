import { defineAsyncComponent } from 'vue';
import Multiselect from 'vue-multiselect';
import globalComponents from './globalComponents';

const VTreeSelectField = defineAsyncComponent(() => import('./VTreeSelectField'));
const WrapperMultiSelect = defineAsyncComponent(() => import('./WrapperMultiSelect'));
const DefaultField = defineAsyncComponent(() => import('./DefaultField'));
const DateField = defineAsyncComponent(() => import('./DateField'));
const TextField = defineAsyncComponent(() => import('./TextField'));
const TextShowField = defineAsyncComponent(() => import('./TextShowField'));
const CkeditorField = defineAsyncComponent(() => import('./CkeditorField'));
const NumberField = defineAsyncComponent(() => import('./NumberField'));
const RadioField = defineAsyncComponent(() => import('./RadioField'));
const BooleanField = defineAsyncComponent(() => import('./BooleanField'));
const VMultiSelectField = defineAsyncComponent(() => import('./VMultiSelectField'));
const SelectTimeField = defineAsyncComponent(() => import('./SelectTimeField'));
const PhoneField = defineAsyncComponent(() => import('./PhoneField'));
const IINField = defineAsyncComponent(() => import('./IINField'));
const SearchIINField = defineAsyncComponent(() => import('./SearchIINField'));
const ListStatementsField = defineAsyncComponent(() => import('./ListStatementsField'));
const BetweenField = defineAsyncComponent(() => import('./BetweenField'));
const SelectUserFileField = defineAsyncComponent(() => import('./SelectUserFileField'));
const EmptyField = defineAsyncComponent(() => import('./EmptyField'));

const MultipleFilesField = defineAsyncComponent(() => import('./MultipleFilesField'));
const FileField = defineAsyncComponent(() => import('./FileField'));
const PhotoField = defineAsyncComponent(() => import('./PhotoField'));
const TextAreaField = defineAsyncComponent(() => import('./TextAreaField'));
const DatePicker = defineAsyncComponent(() => import('./DatePicker'));
const VYearMonthPicker = defineAsyncComponent(() => import('./VYearMonthPicker'));
const WrapperMultiFileField = defineAsyncComponent(() => import('./WrapperMultiFileField'));
const WrapperFileField = defineAsyncComponent(() => import('./WrapperFileField'));
const VRelatedTextField = defineAsyncComponent(() => import('./VRelatedTextField'));
const WrapperCKEditorField = defineAsyncComponent(() => import('./WrapperCKEditorField'));
const SelectFromListCheckboxField = defineAsyncComponent(() => import('./SelectFromListCheckboxField'));
const WrapperTableMultiFileField = defineAsyncComponent(() => import('./WrapperTableMultiFileField'));

// Quasar form components
const QBetweenField = defineAsyncComponent(() => import('./QBetweenField'));
const QDateField = defineAsyncComponent(() => import('./QDateField'));
const QTimeField = defineAsyncComponent(() => import('./QTimeField'));
const QTextField = defineAsyncComponent(() => import('./QTextField'));
const QTextFieldRawControl = defineAsyncComponent(() => import('./QTextFieldRawControl'));
const QNumberField = defineAsyncComponent(() => import('./QNumberField'));
const QSelectField = defineAsyncComponent(() => import('./QSelectField'));
const WrapperQSelect = defineAsyncComponent(() => import('./WrapperQSelect'));
const WrapperQDate = defineAsyncComponent(() => import('./WrapperQDate'));
const WrapperQTime = defineAsyncComponent(() => import('./WrapperQTime'));
const WrapperQDateTime = defineAsyncComponent(() => import('./WrapperQDateTime'));
const WrapperQNumberInput = defineAsyncComponent(() => import('./WrapperQNumberInput'));
const QMultiCheckboxField = defineAsyncComponent(() => import('./QMultiCheckboxField'));
const VMultiQInputField = defineAsyncComponent(() => import('./VMultiQInputField'));
const QCheckboxField = defineAsyncComponent(() => import('./QCheckboxField'));
const QRadioField = defineAsyncComponent(() => import('./QRadioField'));
const VYearMonthPickerField = defineAsyncComponent(() => import('./VYearMonthPickerField'));

export default function (Vue) {
  globalComponents(Vue);

  Vue.component('VTreeSelectField', VTreeSelectField);
  Vue.component('VMultiselect', Multiselect);
  Vue.component('DefaultField', DefaultField);
  Vue.component('DateField', DateField);
  Vue.component('TextField', TextField);
  Vue.component('TextShowField', TextShowField);
  Vue.component('CkeditorField', CkeditorField);
  Vue.component('NumberField', NumberField);
  Vue.component('RadioField', RadioField);
  Vue.component('BooleanField', BooleanField);
  Vue.component('VMultiSelectField', VMultiSelectField);
  Vue.component('MultipleFilesField', MultipleFilesField);
  Vue.component('FileField', FileField);
  Vue.component('PhotoField', PhotoField);
  Vue.component('TextareaField', TextAreaField);
  Vue.component('VWrapperMultiSelect', WrapperMultiSelect);
  Vue.component('VWrapperTableMultiFileField', WrapperTableMultiFileField);

  Vue.component('BetweenField', BetweenField);

  Vue.component('SelectTimeField', SelectTimeField);
  Vue.component('PhoneField', PhoneField);
  Vue.component('IinField', IINField);
  Vue.component('SearchIinField', SearchIINField);
  Vue.component('ListStatementsField', ListStatementsField);
  Vue.component('VueDatePicker', DatePicker);
  Vue.component('SelectUserFileField', SelectUserFileField);
  Vue.component('VYearMonthPicker', VYearMonthPicker);
  Vue.component('VWrapperMultiFilesField', WrapperMultiFileField);
  Vue.component('VWrapperFileField', WrapperFileField);
  Vue.component('VRelatedTextField', VRelatedTextField);
  Vue.component('VWrapperCkeditorField', WrapperCKEditorField);
  Vue.component('VSelectFromListCheckboxField', SelectFromListCheckboxField);
  Vue.component('VEmptyField', EmptyField);

  // Quasar form components
  Vue.component('QBetweenField', QBetweenField);
  Vue.component('QDateField', QDateField);
  Vue.component('QTimeField', QTimeField);
  Vue.component('QSelectField', QSelectField);
  Vue.component('VWrapperQSelect', WrapperQSelect);
  Vue.component('VWrapperQDate', WrapperQDate);
  Vue.component('VWrapperQTime', WrapperQTime);
  Vue.component('VWrapperQDateTime', WrapperQDateTime);
  Vue.component('VWrapperQNumberInput', WrapperQNumberInput);
  Vue.component('QTextField', QTextField);
  Vue.component('QTextFieldRawControl', QTextFieldRawControl);
  Vue.component('QNumberField', QNumberField);
  Vue.component('QMultiCheckboxField', QMultiCheckboxField);
  Vue.component('VMultiQInputField', VMultiQInputField);
  Vue.component('QCheckboxField', QCheckboxField);
  Vue.component('QRadioField', QRadioField);
  Vue.component('VYearMonthPickerField', VYearMonthPickerField);
}
