import { createApp, defineAsyncComponent } from 'vue';

const VScheduleView = defineAsyncComponent(() => import('../VueJS/views/VScheduleView/index'));

export default function (mixBoot) {
  if (document.getElementById('v-schedule-view-app')) {
    const VueApp = createApp({});
    mixBoot(VueApp, false);
    VueApp.component('v-schedule-view', VScheduleView);
    VueApp.mount('#v-schedule-view-app');
    window.vApplicationListViewApp = VueApp;
  }
}
