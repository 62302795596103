import { createApp, defineAsyncComponent } from 'vue';

const VDiplomaForm = defineAsyncComponent(() => import('../VueJS/views/VDiplomaForm/index'));

export default function (mixBoot) {
  if (document.getElementById('v-diploma-form-app')) {
    const VueApp = createApp({});
    mixBoot(VueApp, false);
    VueApp.component('v-diploma-form', VDiplomaForm);
    VueApp.mount('#v-diploma-form-app');
    window.vDiplomaFormApp = VueApp;
  }
}
