import _ from 'lodash';

function transportToQuestion(subject, direction = true, state = {}, fromSubjectTransport = false) {
  for (let i = 0; i < subject.questions.length; i++) {
    // Если попадаем на неотвеченный вопрос из transportToSubject, то выходим из цикла
    if (fromSubjectTransport) {
      const checkAnswers = _.find(
        subject.questions[subject.curQuestionIdx].answers,
        (o) => o.checked,
      );

      if (!checkAnswers) {
        localStorage.setItem('curSubjectID', subject.id);
        localStorage.setItem('curQuestionID', subject.curQuestionID);
        localStorage.setItem('curQuestionIdx', subject.curQuestionIdx);
        const curIdx = parseInt(subject.curQuestionIdx / 20, 10);

        if (curIdx !== state.curQuestionsMapPage) {
          state.curQuestionsMapPage = curIdx;
        }

        break;
      }
    }

    // Расчёт индекса в зависимости от направления (true - вперёд)
    if (direction) {
      // Проверка для переключения вопросов (вперёд)
      if (subject.curQuestionIdx + 1 < subject.questions.length) {
        subject.curQuestionIdx += 1;
      } else {
        subject.curQuestionIdx = 0;
      }
    } else {
      // Проверка для переключения вопросов (в обратную сторону)
      if (subject.curQuestionIdx - 1 >= 0) {
        subject.curQuestionIdx -= 1;
      } else {
        subject.curQuestionIdx = subject.questions.length - 1;
      }
    }

    // Проверка выбран ли ответ у следующего/предыдущего вопроса
    const checkAnswers = _.find(
      subject.questions[subject.curQuestionIdx].answers,
      (o) => o.checked,
    );

    // Если не выбран, то переключаем на него иначе продолжаем следующую итерацию
    if (!checkAnswers) {
      subject.curQuestionID = subject.questions[subject.curQuestionIdx].id;
      localStorage.setItem('curSubjectID', subject.id);
      localStorage.setItem('curQuestionID', subject.curQuestionID);
      localStorage.setItem('curQuestionIdx', subject.curQuestionIdx);

      const curIdx = parseInt(subject.curQuestionIdx / 20, 10);
      if (curIdx !== state.curQuestionsMapPage) {
        state.curQuestionsMapPage = curIdx;
      }

      if (subject.leftAnswers === 1) {
        setTimeout(() => {
          state.helpWithLastQuestion = {};
        }, 500);
        state.helpWithLastQuestion = {
          id: subject.curQuestionID,
          idx: subject.curQuestionIdx,
        };
      }

      break;
    }
  }
}

export default transportToQuestion;
