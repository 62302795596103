import { createApp, defineAsyncComponent } from 'vue';

const VRelatedPieCharts = defineAsyncComponent(() => import('../VueJS/views/VRelatedPieCharts'));
const VBarChart = defineAsyncComponent(() => import('../VueJS/views/VBarChart'));
const VPopulationPyramid = defineAsyncComponent(() => import('../VueJS/views/VPopulationPyramid'));
const VRelatedBarPieChart = defineAsyncComponent(() => import('../VueJS/views/VRelatedBarPieChart'));
const VTableChart = defineAsyncComponent(() => import('../VueJS/views/VTableChart'));
const VStatisticBlock = defineAsyncComponent(() => import('../VueJS/views/VStatisticBlock'));
const VPieChart = defineAsyncComponent(() => import('../VueJS/views/VPieChart'));

export default function (mixBoot) {
  if (document.getElementById('v-statistic-app')) {
    const VueApp = createApp({});
    mixBoot(VueApp, false);
    VueApp.component('VRelatedPieCharts', VRelatedPieCharts);
    VueApp.component('VBarChart', VBarChart);
    VueApp.component('VPopulationPyramid', VPopulationPyramid);
    VueApp.component('VRelatedBarPieChart', VRelatedBarPieChart);
    VueApp.component('VTableChart', VTableChart);
    VueApp.component('VStatisticBlock', VStatisticBlock);
    VueApp.component('VPieChart', VPieChart);
    VueApp.mount('#v-statistic-app');
    window.vStatisticApp = VueApp;
  }
}
