import axios from 'axios';
import GlobalVue from '@common/src/vue';
import { Helper } from '@common/src/helpers';

export default {
  // Получает с сервера информацию о тестировании и вопросы по предметам
  GET_TEST: async ({ commit, state }, payload) => {
    try {
      commit('SET_LOADING', { name: 'common', status: true });

      const res = await axios({
        method: 'get',
        url: state.endpoints.loadData,
      });

      if (res.status === 200) {
        const { data } = res;

        if (!data.test || !data.test.status) {
          throw new Error('Ошибка на стороне сервера, попробуйте обновить страницу!');
        }

        if (data.test) {
          // console.time('SET_TEST');
          commit('SET_TEST', data.test);
          // console.timeEnd('SET_TEST');
        }

        if (data.subjects) {
          commit('SET_DATA', data.subjects);
        }

        setTimeout(() => commit('SET_LOADING', { name: 'common', status: false }), 1000);
      }
    } catch (err) {
      if (err.response) {
        Helper.handlerResponseError(err.response, () => {
          commit('SET_LOADING_MESSAGE', GlobalVue.trans.get('main.server_error'));
        });
      } else {
        commit('SET_LOADING_MESSAGE', GlobalVue.trans.get('main.server_error'));
        throw err;
      }
    }
  },

  GET_TIME: async ({ commit, state }, payload) => {
    try {
      const res = await axios({
        method: 'get',
        url: state.endpoints.loadTime,
      });

      if (res.status === 200) {
        const { data } = res;

        commit('SET_TEST_TIME', data);

        setTimeout(() => commit('SET_LOADING', {
          name: 'common',
          status: false,
        }), 1000);
      }
    } catch (err) {
      const { response } = err;
      if (response.status === 302 && response.data?.redirect) {
        window.onbeforeunload = null;
        window.location.replace(response.data.redirect);
        return;
      }
      if (response) {
        Helper.handlerResponseError(response, () => {
          commit('SET_LOADING_MESSAGE', GlobalVue.trans.get('main.server_error'));
        });
      } else {
        commit('SET_LOADING_MESSAGE', GlobalVue.trans.get('main.server_error'));
        throw err;
      }
    }
  },

  // Отправляет выбранный ответ на сервер и в store
  SEND_ANSWER: async ({ commit, getters, state }, payload) => {
    try {
      commit('SET_LOADING', { name: 'answer', status: true });
      const res = await axios({
        method: 'post',
        url: state.endpoints.storeAnswer,
        data: payload,
      });
      if (res.data.redirect) {
        window.onbeforeunload = null;
        window.location.replace(res.data.redirect);
      }
      if (res.status === 200) {
        commit('SET_ANSWER', payload);

        /* Если после выбора ответа сразу была нажата кнопка "следующий вопрос",
           то не переводим тут на следующий
        */
        if (getters.CURRENT_QUESTION.id === payload.questionId) {
          if (getters.TEST.status === 'begin') {
            commit('SET_NEXT_QUESTION_SMART');
          } else {
            commit('SET_NEXT_QUESTION');
          }
        }
      }
    } catch (err) {
      if (err.response) {
        Helper.handlerResponseError(err.response);
      }
    } finally {
      commit('SET_LOADING', { name: 'answer', status: false });
    }
  },

  // Отправляет запрос о завершении теста
  END_TEST: async ({ commit, dispatch, state }) => {
    try {
      commit('SET_LOADING', { name: 'common', status: true });
      const res = await axios({
        method: 'post',
        url: state.endpoints.storeResult,
      });
      if (res.data.redirect) {
        window.onbeforeunload = null;
        window.location.replace(res.data.redirect);
      }
      if (res.status === 200) {
        dispatch('GET_TEST');
      }
    } catch (err) {
      if (err.response) {
        Helper.handlerResponseError(err.response);
      }
    } finally {
      commit('SET_LOADING', { name: 'common', status: false });
    }
  },
};
