import { createApp, defineAsyncComponent } from 'vue';

const VTicketProcess = defineAsyncComponent(() => import('../VueJS/views/VTicketProcess/index'));

export default function (mixBoot) {
  if (document.getElementById('v-ticket-process-app')) {
    const VueApp = createApp({});
    mixBoot(VueApp, false);
    VueApp.component('v-ticket-process', VTicketProcess);
    VueApp.mount('#v-ticket-process-app');
    window.vTicketProcessApp = VueApp;
  }
}
