import { defineAsyncComponent } from 'vue';

const JournalEditPoint = defineAsyncComponent(() => import('./Journal/JournalEditPoint'));
const JournalGenerateWeeks = defineAsyncComponent(() => import('./Journal/JournalGenerateWeeks'));
const JournalCalcResult = defineAsyncComponent(() => import('./Journal/JournalCalcResult'));
const JournalDayForm = defineAsyncComponent(() => import('./Journal/JournalDayForm'));
const Confirm = defineAsyncComponent(() => import('./Confirm'));
const ConfirmCustomActions = defineAsyncComponent(() => import('./ConfirmCustomActions'));
const ConfirmNew = defineAsyncComponent(() => import('./ConfirmNew'));
const ConfirmWithMessage = defineAsyncComponent(() => import('./ConfirmWithMessage'));
const StatementAttestationAveragePoint = defineAsyncComponent(() => import('./StatementAttestationAveragePoint'));
const SelectUserFile = defineAsyncComponent(() => import('../../views/SelectUserFile'));
const ReassignSpecialtyPlaces = defineAsyncComponent(() => import('./ReassignSpecialtyPlaces'));
const VAttendanceModal = defineAsyncComponent(() => import('./Attendance/DayAttendance'));
const VContingentAttendanceModal = defineAsyncComponent(() => import('./Attendance/ContingentAttendance'));
const VMultipleButtonsModal = defineAsyncComponent(() => import('./MultipleButtons'));
const VModalExamQuestionsImport = defineAsyncComponent(() => import('./ExamQuestions/Import'));
const VHolidaysModal = defineAsyncComponent(() => import('./Attendance/Holidays'));
const ProgramChapter = defineAsyncComponent(() => import('./CurriculumWorkingProgram/ProgramChapter'));
const ProgramLesson = defineAsyncComponent(() => import('./CurriculumWorkingProgram/ProgramLesson'));
const ProgramDuplicate = defineAsyncComponent(() => import('./CurriculumWorkingProgram/ProgramDuplicate'));
const VQuestionVisibilityModal = defineAsyncComponent(() => import('./ExamQuestions/VQuestionVisibilityModal'));
const MultiExportModal = defineAsyncComponent(() => import('./MultiExportModal'));
const VGraduatedContingentForm = defineAsyncComponent(() => import('./Contingent'));
const StatementSocialStatus = defineAsyncComponent(() => import('./StatementSocialStatus'));
const EditDiplomaMargins = defineAsyncComponent(() => import('./EditDiplomaMargins'));
const DuplicateContingentTranscript = defineAsyncComponent(() => import('./DuplicateContingentTranscript'));
const TranscriptChangePosition = defineAsyncComponent(() => import('./TranscriptChangePosition'));
const CurriculumAddSection = defineAsyncComponent(() => import('./CurriculumAddSection'));
const DormitoryRoomModal = defineAsyncComponent(() => import('./Dormitory/RoomModal'));
const DormitoryToAnotherRoomModal = defineAsyncComponent(() => import('./Dormitory/ToAnotherRoomModal'));
const SelectFileByElementModal = defineAsyncComponent(() => import('./SelectFileByElementModal'));
const StatementAppealModal = defineAsyncComponent(() => import('./StatementAppealModal'));
const VAgreementModal = defineAsyncComponent(() => import('./VAgreementModal'));
const CurriculumDeleteSection = defineAsyncComponent(() => import('./CurriculumDeleteSection'));
const VUploadFIleModal = defineAsyncComponent(() => import('./Settings/VUploadFIleModal'));
const SettingConfirmModal = defineAsyncComponent(() => import('./Settings/SettingConfirmModal'));
const ExceptionUsersModal = defineAsyncComponent(() => import('./Settings/ExceptionUsersModal'));
const VAddPracticeInfo = defineAsyncComponent(() => import('./Practice'));
const VAddPracticeDiary = defineAsyncComponent(() => import('./Practice/Diary'));
const EducationResultContingentTranscript = defineAsyncComponent(() => import('./EducationResultContingentTranscript'));
const DocumentFlow = defineAsyncComponent(() => import('./DocumentFlow'));
const CalendarThematicPlanDuplicateModal = defineAsyncComponent(() => import('./CalendarThematicPlanDuplicateModal'));
const ContingentSocialAchievementAdd = defineAsyncComponent(() => import('./ContingentSocialAchievementAdd'));
const PracticeGradeModal = defineAsyncComponent(() => import('./Practice/PracticeGradeModal'));
const PracticeCompletionModal = defineAsyncComponent(() => import('./Practice/PracticeCompletionModal'));
const DiplomaFullNameDeclensionModal = defineAsyncComponent(() => import('./DiplomaFullNameDeclensionModal'));
const EditDiplomaSubjectsCountModal = defineAsyncComponent(() => import('./Settings/EditDiplomaSubjectsCountModal'));
const JournalDayPlanDuplicate = defineAsyncComponent(() => import('./Journal/JournalDayPlanDuplicate'));
const ContingentTranscriptExportModal = defineAsyncComponent(() => import('./ContingentTranscriptExportModal'));

export default function (Vue) {
  Vue.component('VModalJournalEditPoint', JournalEditPoint);
  Vue.component('VModalJournalGenerateWeeks', JournalGenerateWeeks);
  Vue.component('VModalJournalCalcResult', JournalCalcResult);
  Vue.component('VModalJournalDayForm', JournalDayForm);
  Vue.component('VModalConfirm', Confirm);
  Vue.component('VModalConfirmCustomActions', ConfirmCustomActions);
  Vue.component('VModalConfirmNew', ConfirmNew);
  Vue.component('VModalStatementAttestationAveragePoint', StatementAttestationAveragePoint);
  Vue.component('VModalSelectUserFiles', SelectUserFile);
  Vue.component('VModalReassignSpecialtyPlaces', ReassignSpecialtyPlaces);
  Vue.component('VModalConfirmWithMessage', ConfirmWithMessage);
  Vue.component('VModalAttendance', VAttendanceModal);
  Vue.component('VModalContingentAttendance', VContingentAttendanceModal);
  Vue.component('VModalMultiButton', VMultipleButtonsModal);
  Vue.component('VModalExamQuestionsImport', VModalExamQuestionsImport);
  Vue.component('VHolidaysModal', VHolidaysModal);
  Vue.component('VModalAddChapter', ProgramChapter);
  Vue.component('VModalAddLesson', ProgramLesson);
  Vue.component('VModalProgramDuplicate', ProgramDuplicate);
  Vue.component('VQuestionVisibilityModal', VQuestionVisibilityModal);
  Vue.component('VMultipleExportModal', MultiExportModal);
  Vue.component('VGraduatedContingentFormModal', VGraduatedContingentForm);
  Vue.component('VStatementSocialStatusModal', StatementSocialStatus);
  Vue.component('VModalEditDiplomaMargins', EditDiplomaMargins);
  Vue.component('VModalDuplicateContingentTranscript', DuplicateContingentTranscript);
  Vue.component('VModalTranscriptChangePosition', TranscriptChangePosition);
  Vue.component('VModalCurriculumAddSection', CurriculumAddSection);
  Vue.component('VDormitoryRoomModal', DormitoryRoomModal);
  Vue.component('VDormitoryToAnotherRoomModal', DormitoryToAnotherRoomModal);
  Vue.component('VSelectFileByElementModal', SelectFileByElementModal);
  Vue.component('VStatementAppealModal', StatementAppealModal);
  Vue.component('VAgreementModal', VAgreementModal);
  Vue.component('VModalCurriculumDeleteSection', CurriculumDeleteSection);
  Vue.component('VModalUploadFile', VUploadFIleModal);
  Vue.component('VSettingConfirmModal', SettingConfirmModal);
  Vue.component('VExceptionUserModal', ExceptionUsersModal);
  Vue.component('VAddPracticeModal', VAddPracticeInfo);
  Vue.component('VAddPracticeDiaryModal', VAddPracticeDiary);
  Vue.component('VModalEducationResultContingentTranscript', EducationResultContingentTranscript);
  Vue.component('VDocumentFlowModal', DocumentFlow);
  Vue.component('VCalendarThematicPlanDuplicateModal', CalendarThematicPlanDuplicateModal);
  Vue.component('VContingentSocialAchievementAdd', ContingentSocialAchievementAdd);
  Vue.component('VPracticeGradeModal', PracticeGradeModal);
  Vue.component('VPracticeCompletionModal', PracticeCompletionModal);
  Vue.component('VDiplomaFullNameDeclensionModal', DiplomaFullNameDeclensionModal);
  Vue.component('VEditDiplomaSubjectCountModal', EditDiplomaSubjectsCountModal);
  Vue.component('VModalJournalDayPlanDuplicate', JournalDayPlanDuplicate);
  Vue.component('VContingentTranscriptExportModal', ContingentTranscriptExportModal);
}
