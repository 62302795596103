import { createApp, defineAsyncComponent } from 'vue';

const VStatementFilesEdit = defineAsyncComponent(() => import('../VueJS/views/VStatementFilesEdit/index'));

export default function (mixBoot) {
  if (document.getElementById('v-statement-files-edit-app')) {
    const VueApp = createApp({});
    mixBoot(VueApp, false);
    VueApp.component('VStatementFilesEdit', VStatementFilesEdit);
    VueApp.mount('#v-statement-files-edit-app');
    window.vStatementFilesEditApp = VueApp;
  }
}
